
import { Badkamercore_ProjectType, DesignPackageWallDtoInput, IsTilesToCeiling, ProductPackageSet } from '@/__generated__/globalTypes'
import AreaMeasurementField from '@/components/updateProductSetInfos/AreaMeasurementField.vue'
import LengthMeasurementField from '@/components/updateProductSetInfos/LengthMeasurementField.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import BoolField from './BoolField.vue'
import DecimalField from './DecimalField.vue'
import EnumField from './EnumField.vue'
import MultiEnumField from './MultiEnumField.vue'
import { GuidEmpty } from './types'


@Component({
  components: {
    BoolField,
    EnumField,
    MultiEnumField,
    DecimalField,
    LengthMeasurementField,
    AreaMeasurementField
  }
})
export default class WallManagementComponent extends Vue {
  @Prop({ default: () => [] }) assignedWalls!: DesignPackageWallDtoInput[]
  @Prop({ required: true }) assignedDefaultProducts!: ProductPackageSet[]
  @Prop({ required: true }) assignedPreselectedProducts!: ProductPackageSet[]
  @Prop() disabled!: boolean
  @Prop({ required: true }) isPriceVariant!: boolean
  @Prop({ required: true }) isTilesToCeiling!: IsTilesToCeiling
  @Prop({ required: true }) projectType!: Badkamercore_ProjectType

  wallsModel: DesignPackageWallDtoInput[] = []
  isTilesToCeilingModel = this.isTilesToCeiling
  GuidEmpty = GuidEmpty
  tileToCeilingPreselections = [
    { text: this.$t('projectDesignPackage.labels.walls.tilesToCeilingAnswers.noPreselection'), value: IsTilesToCeiling.NoPreselection , disabled: true },
    { text: this.$t('projectDesignPackage.labels.walls.tilesToCeilingAnswers.yes'), value: IsTilesToCeiling.YesToCeiling },
    { text: this.$t('projectDesignPackage.labels.walls.tilesToCeilingAnswers.no'), value: IsTilesToCeiling.NoToCeiling }
  ]

  @Watch('assignedWalls', { immediate: true })
  onAssignedWallsChange() {
    this.wallsModel = this.assignedWalls
  }

  @Watch('isTilesToCeiling', { immediate: true })
  onIsTilesToCeilingChange() {
    this.isTilesToCeilingModel = this.isTilesToCeiling
  }

  @Watch('isTilesToCeilingModel' , { immediate: true })
  onIsTilesToCeilingModelChange() {
    if (this.isTilesToCeilingModel === IsTilesToCeiling.NoPreselection) {
      this.rules.required = (value: string) => {
        if (value === null || value.length === 0 || value == IsTilesToCeiling.NoPreselection) return this.$t('global.validationRequired')
        return true
      }
    } else {
      this.rules.required = () => true
    }
  }

  @Watch('wallsModel', { deep: true })
  onWallsModelChange() {
    const emitWalls = this.wallsModel.slice()
    emitWalls.forEach((w) => {
      w.accentTileDefaultProductSetId =
        w.isAccentWall && [null, undefined, GuidEmpty].includes(w.accentTileDefaultProductSetId)
          ? GuidEmpty
          : w.accentTileDefaultProductSetId
      w.accentTilePreselectedProductSetId =
        w.isAccentWall && [null, undefined, GuidEmpty].includes(w.accentTilePreselectedProductSetId)
          ? GuidEmpty
          : w.accentTilePreselectedProductSetId
    })

    this.$emit('walls-updated', this.wallsModel)
  }

  get isStylesProject() {
    return this.projectType === Badkamercore_ProjectType.DeBadkamerwerelden
  }

  get isBrandsProject() {
    return this.projectType === Badkamercore_ProjectType.Merkenpropositie
  }

  get rules() {
    return {
      required: (value: string) => {
        if (value === null || value.length === 0) return this.$t('global.validationRequired')
        return true
      },
      decimalPlaces2: (value: string) => {
        // Can contain percentages between 0 and 100
        const regex = /^[0-9]*([,.][0-9]{1,2})?$/
        return !value?.toString()?.length || regex.test(value) || this.$t('global.validationDecimalPlaces2')
      }
    }
  }

  get wallTableheaders(): DataTableHeader[] {
    const headers = [
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.name').toString(),
        value: 'name',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.area').toString(),
        value: 'area',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.profileLength').toString(),
        value: 'profileLength',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.ceilingArea').toString(),
        value: 'ceilingArea',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.ceilingLength').toString(),
        value: 'ceilingLength',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.accentWall').toString(),
        value: 'isAccentWall',
        width: '6%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.defaultAccentTile').toString(),
        value: 'accentTileDefaultProductSetId',
        width: '20%',
        sortable: false
      },
      {
        text: this.$t('projectDesignPackage.labels.walls.tableHeaders.preselectedAccentTile').toString(),
        value: 'accentTilePreselectedProductSetId',
        width: '20%',
        sortable: false
      },
      { text: '', value: 'delete', width: '5%', sortable: false }
    ]

    if (!this.isPriceVariant) {
      return headers.filter((h) => h.value != 'accentTilePreselectedProductSetId')
    }

    return headers
  }

  get amountOfAccentWalls() {
    return this.wallsModel.filter((x) => !!x.isAccentWall).length
  }

  get defaultProductSets() {
    if (!this.assignedDefaultProducts?.length) return []
    return this.assignedDefaultProducts.map((x) => ({ text: x.productSet?.name, value: x.productSet.id }))
  }

  get preselectedProductSets() {
    if (!this.assignedPreselectedProducts?.length) return []
    return this.assignedPreselectedProducts.map((x) => ({ text: x.productSet?.name, value: x.productSet.id }))
  }

  onIsAccentWallChange(wall: DesignPackageWallDtoInput) {
    if (wall.isAccentWall) {
      wall.accentTileDefaultProductSetId = GuidEmpty
      wall.accentTilePreselectedProductSetId = GuidEmpty
    } else {
      wall.accentTileDefaultProductSetId = wall.accentTilePreselectedProductSetId = null
    }
  }

  addWall() {
    this.wallsModel.push({
      name: '',
      area: 0,
      ceilingArea: 0,
      ceilingLength: 0,
      isAccentWall: false,
      profileLength: 0,
      accentTileDefaultProductSetId: null
    })
  }

  removeWall(index: number) {
    this.wallsModel.splice(index, 1)
  }
}
