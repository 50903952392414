/* eslint-disable */
import type * as Types from '../../../../__generated__/globalTypes';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BrandsViewQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BrandsViewQueryQuery = { brands: Array<{ id: any, name: string, productPackageId?: any | null, productPackage?: { id: any, isMain: boolean, hasImportFile: boolean } | null }> };

export type BrandFragment = { id: any, name: string, productPackageId?: any | null, productPackage?: { id: any, isMain: boolean, hasImportFile: boolean } | null };

export const BrandFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"brand"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Brand"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"productPackageId"}},{"kind":"Field","name":{"kind":"Name","value":"productPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isMain"}},{"kind":"Field","name":{"kind":"Name","value":"hasImportFile"}}]}}]}}]} as unknown as DocumentNode<BrandFragment, unknown>;
export const BrandsViewQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BrandsViewQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brands"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"brand"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"brand"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Brand"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"productPackageId"}},{"kind":"Field","name":{"kind":"Name","value":"productPackage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isMain"}},{"kind":"Field","name":{"kind":"Name","value":"hasImportFile"}}]}}]}}]} as unknown as DocumentNode<BrandsViewQueryQuery, BrandsViewQueryQueryVariables>;