export class FileApiService {
  resource = '/api/file'
  baseUrl!: string
  token!: string

  constructor(baseUrl: string, token: string) {
    this.baseUrl = baseUrl + this.resource
    this.token = token
  }

  async uploadFile(path: string, file: File) {
    const headers = new Headers()
    headers.append('Authorization', this.token ? `Bearer ${this.token}` : '')

    const formData = new FormData()
    formData.append('file', file, file.name)

    const url = `${this.baseUrl}${path}`

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: formData
    })

    if (response.ok) {
      return true
    }

    const text = await response.text()
    throw text
  }

  async downloadFile(path: string, filename: string) {
    const uri = `${this.baseUrl}${path}`
    const headers = new Headers()
    headers.append('Authorization', this.token ? `Bearer ${this.token}` : '')
    const response = await fetch(uri, { headers: headers })

    if (!response.ok) {
      const error = await response.text()
      throw error
    }

    const data = await response.blob()
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(data)

    data.type === 'application/vnd.ms-outlook' ? (a.download = filename + '.msg') : (a.download = filename)

    a.click()
    a.remove()
  }
}
