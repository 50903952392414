
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateTimeString } from '@/utils/dateUtils'
import { ContactType, MessageDto, MessageStatus } from '@/__generated__/globalTypes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MessageThread from '@/components/messageThread/MessageThread.vue'
import CloseContactRequestDialog from '@/components/closeContactRequestDialog/CloseContactRequestDialog.vue'
import {
  GetContactRequestsDetailsQuery,
  GetContactRequestsDetailsQueryVariables,
  GetContactRequestsDetailsDocument,
  ContactRequestDetailsFragment,
  ContactRequestMessageDetailFragment,
  ContactRequestFragment
} from './gql/__generated__/getContactRequestDetails.query'
import {
  UpdateContactRequestStatusDocument,
  UpdateContactRequestStatusMutation,
  UpdateContactRequestStatusMutationVariables
} from './gql/__generated__/updateContactRequestStatus.mutation'

@Component({
  components: {
    MessageThread,
    CloseContactRequestDialog
  }
})
export default class ContactRequestDetailsView extends Vue {
  @Prop({ required: true }) contactRequestId!: string
  contactRequestQueryResult: ContactRequestFragment | null = null
  contactRequestMessage: ContactRequestDetailsFragment | null = null
  MessageStatus = MessageStatus
  RouteNames = RouteNames
  showCompleteDialog = false
  isLoading = false

  created() {
    this.isLoading = true
    this.$apollo.addSmartQuery<GetContactRequestsDetailsQuery, GetContactRequestsDetailsQueryVariables>(
      QUERIES.GetContactRequestsDetailsQuery,
      {
        query: GetContactRequestsDetailsDocument,
        variables: () => ({ requestId: this.contactRequestId }),
        fetchPolicy: 'no-cache',
        update: (data) => data,
        result: (result) => {
          this.contactRequestQueryResult = result.data?.contactRequestByIdAsync ?? null
          // The first message is the contact request of the user, the second message the admin closing note (only for completed contact requests)
          this.contactRequestMessage = result.data?.contactRequestByIdAsync?.messages?.[0] ?? null

          if (this.contactRequestMessage?.status === MessageStatus.New) {
            this.changeStatus(MessageStatus.Pending)
          }

          this.isLoading = false
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4242',
            Message: error
          })
          this.isLoading = false
        }
      }
    )
  }

  get language() {
    return this.$i18n.locale
  }

  get contactRequestMessages() {
    return (this.contactRequestQueryResult?.messages ?? []) as ContactRequestMessageDetailFragment[]
  }

  get contactRequestMessageId(): string {
    return this.contactRequestMessage?.id ?? ''
  }

  get unauthenticatedUserName() {
    return this.contactRequestMessage?.__typename === 'ContactUnauthenticatedMessageDto'
      ? this.contactRequestMessage?.name
      : ''
  }

  get sender() {
    return this.unauthenticatedUserName
      ? `${this.unauthenticatedUserName} - ${this.contactRequestMessage?.sender}`
      : this.contactRequestMessage?.sender
  }

  get realEstate() {
    // The real estate is only available for contact requests of authenticated users, which are of type MessageDto
    return this.contactRequestMessage?.__typename === 'MessageDto' ? this.contactRequestMessage?.realEstate : null
  }

  get contactRequestPhoneNumber() {
    const requestPhoneNumber = this.contactRequestQueryResult?.phone
    const realEstateContactPhoneNumber = this.realEstate?.contact?.phone?.number
    return requestPhoneNumber || realEstateContactPhoneNumber
  }

  get contactRequestPreference() {
    return this.contactRequestQueryResult?.contactType ?? ContactType.NoPreference
  }

  get isCompleted() {
    return this.contactRequestMessage?.status === MessageStatus.Closed
  }

  get requestDate() {
    return toLocaleDateTimeString(this.contactRequestMessage?.dateCreated, this.language)
  }

  get modificationDate() {
    return toLocaleDateTimeString(
      this.contactRequestMessage?.dateModified ?? this.contactRequestMessage?.dateCreated,
      this.language
    )
  }

  closeCloseContactRequestPopup() {
    this.showCompleteDialog = false
  }

  completeRequest() {
    this.showCompleteDialog = true
  }

  async changeStatus(status: MessageStatus) {
    const result = await this.$apolloMutate<
      UpdateContactRequestStatusMutation,
      UpdateContactRequestStatusMutationVariables
    >({
      mutation: UpdateContactRequestStatusDocument,
      variables: {
        requestId: this.contactRequestMessageId,
        status
      },
      error: 'E4206'
    })

    if (result !== false && result.data?.updateMessageStatus) {
      this.contactRequestMessage = {
        ...this.contactRequestMessage,
        ...result.data.updateMessageStatus
      } as ContactRequestDetailsFragment
    }
  }

  getMessageDateAndSender(msg: MessageDto) {
    return `[${new Date(Date.parse(msg.dateCreated)).toLocaleDateString(this.language, {
      dateStyle: 'full'
    })}] ${msg.sender}:`
  }
}
