import { InternationalizationType } from '../types/InternationalizationType'

const dutch: InternationalizationType = {
  global: {
    actions: 'Acties',
    cancel: 'Annuleren',
    close: 'Sluiten',
    confirm: 'Bevestigen',
    delete: 'Verwijderen',
    details: 'Details',
    isRequired: 'is een verplicht veld',
    status: 'Status',
    enable: 'Activeer',
    disable: 'Deactiveer',
    date: 'Datum',
    time: 'Tijd',
    activities: 'Activiteiten',
    download: 'Download',
    copyToClipboard: 'Kopiëren naar klembord',
    copiedToClipboard: 'Gekopieerd naar klembord',
    combinations: {
      bathtub: 'Bad',
      fountain: 'Fontein',
      radiator: 'Radiator',
      shower: 'Douche',
      tiles: 'Tegels',
      toilet: 'Toilet',
      utilities: 'Accessoires',
      washbasin: 'Wastafel',
      undefined: 'Geen combinatie'
    },
    uploadFileTypes: {
      CUSTOM_OFFER: 'Maatwerk',
      DRAWING3_D: '3d tekening',
      MANUAL: 'Handmatig',
      TECHNICAL_DRAWING: 'Technische tekening'
    },
    costTypes: {
      COORDINATION_COMMISSION_COSTS: 'Coördinatie en provisie',
      CUSTOM_OFFER_COSTS: 'Maatwerk',
      CV_INSTALLATION_COSTS: 'CV installatie',
      DRAWING_COSTS: '3D Tekeningen',
      E_INSTALLATION_COSTS: 'E installatie',
      INSTALLATION_CONSTRUCTION_COSTS: 'Installatie & bouwkundige kosten',
      LOWER_PRICE_STANDARD_PRODUCTS_COSTS: 'Minderprijs standaard producten',
      PRODUCTS_AND_INSTALLATION_MATERIALS_COSTS: 'Producten en installatiematerialen',
      COORDINATION_COMMISSION_INSTALLER_COSTS: 'Opslag coördinatie & risico installateur sanitair',
      COORDINATION_COMMISSION_BUILDER_COSTS: 'Opslag coördinatie & risico aannemer sanitair',
      TILER_INSTALLATION_COSTS: 'Installatie tegelwerk',
      TILES_AND_FASTENERS_COSTS: 'Tegels en bevestigingsmaterialen',
      TOTAL_SURCHARGE_COSTS: 'Totale meerprijs',
      VAT_COSTS: 'BTW',
      W_INSTALLATION_COSTS: 'W installatie',
      KITTER_INSTALLATION_COSTS: 'Kitter'
    },
    selectionTypes: {
      CUSTOM: 'Maatwerk',
      DESIGN_PACKAGE: 'Designpakket',
      SHELL_ONLY: 'Casco',
      STANDARD: 'Standaard',
      GLOBAL_CUSTOM: 'Maatwerk'
    },
    realEstateStatuses: {
      UNSOLD: 'Onverkocht',
      NEW_BUYER: 'Nieuwe koper',
      INVITED: 'Uitgenodigd',
      BUYER_CHOOSING: 'Koperskeuze',
      CUSTOM_OFFER: 'Maatwerk',
      SEND_DOSSIER: 'Dossier verzenden',
      COMPLETED: 'Afgerond',
      TECHNICAL_DRAWING: 'Technische tekening'
    },
    customOfferStatuses: {
      APPOINTMENT: 'Afspraak ingepland',
      NEW: 'Nieuw',
      CANCELLED: 'Geannuleerd',
      CREATE_OFFER: 'Offerte aanmaken',
      REQUEST_FOR_CHANGE: 'Wijzigingsverzoek',
      SHARED_WITH_CUSTOMER: 'Gedeeld met klant',
      COMPLETED: 'Afgerond'
    },
    externalActionTargets: {
      CONTRACTOR: 'Aannemer',
      INSTALLER: 'Installateur',
      OTHER: 'Overig'
    },
    customOfferGlobalAppointmentTypes: {
      CONTACT: 'Contact op afstand',
      MEETING: 'Fysieke afspraak',
      NO_COSTS: 'Geen maatwerkkosten'
    },
    customOfferAppointmentTypes: {
      CONTACT: 'Op afstand',
      MEETING: 'Fysiek'
    },
    customOfferContactTypes: {
      EMAIL: 'E-mail',
      NO_PREFERENCE: 'Geen voorkeur',
      PHONE: 'Telefoon',
      NO_SELECTION: 'Geen selectie'
    },
    selectionTypesDefaultSelectionName: {
      STANDARD: 'Standaard configuratie'
    },
    dialogs: {
      confirmation: {
        C4001: {
          description:
            'U staat op het punt om dit project te publiceren. De applicatie stuurt dan uitnodigingen naar alle deelnemers en zij hebben dan direct tot het project. Weet u zeker dat u nu wilt publiceren?',
          title: 'Publiceren project bevestigen'
        },
        C4002: {
          description:
            'U staat op het punt om dit project te depubliceren. Het project wordt dan ontoegankelijk voor deelnemers, terwijl zij waarschijnlijk wel al uitnodigingen hebben ontvangen. Weet u zeker dat u nu wilt depubliceren?',
          title: 'Depubliceren project bevestigen'
        },
        C4003: {
          description:
            'U staat op het punt om dit project af te ronden. Het project wordt dan gesloten en ontoegankelijk voor deelnemers. Weet u zeker dat u nu wilt afronden?',
          title: 'Afronden project bevestigen'
        },
        C4004: {
          description:
            'U staat op het punt om dit project te heropenen. Het project wordt dan terug in concept fase geplaatst en is nog steeds ontoegankelijk voor deelnemers. Weet u zeker dat u nu wilt heropenen?',
          title: 'Heropenen project bevestigen'
        },
        C4005: {
          description:
            'U staat op het punt om een designpakket te verwijderen. Het pakket is dan niet meer selecteerbaar en de content in het CMS wordt gedepubliceerd. Weet u zeker dat u dit designpakket wilt verwijderen?',
          title: 'Verwijderen designpakket bevestigen'
        },
        C4006: {
          description:
            'U staat op het punt om een ruimte te verwijderen. Weet u zeker dat u de volgende ruimte wilt verwijderen?',
          title: 'Verwijderen ruimte bevestigen'
        },
        C4007: {
          description:
            'U staat op het punt om de uitnodiging opnieuw te versturen. Weet u zeker dat u nu wilt versturen?',
          title: 'Versturen bevestigen'
        },
        C4008: {
          description:
            'U staat op het punt om het project en alle gerelateerde data onherroepelijk te verwijderen. Hiermee wordt ook alle project gerelateerde data uit het CMS verwijderd. Weet u zeker dat u het project wilt verwijderen?',
          title: 'Verwijderen project bevestigen'
        },
        C4009: {
          description:
            'U staat op het punt om de voor dit bouwnummer gemaakte offerte aanvragen te resetten. De deelnemer zal dan opnieuw zijn/haar keuze mogen/moeten maken. Weet u zeker dat u nu wilt resetten?',
          title: 'Reset selectie bevestigen'
        },
        C4010: {
          description:
            'Je staat op het punt de maatwerkaanvraag te annuleren, de koper wordt hiervan op de hoogte gesteld. Weet je zeker dat je door wilt gaan?',
          title: 'Bevestig annuleren van aanvraag'
        },
        C4011: {
          description: 'Weet je zeker dat je het type afspraak wilt wijzigen?',
          title: 'Bevestig wijziging type afspraak'
        },
        C4012: {
          description: 'Weet je zeker dat je status van de aanvraag wilt wijzigen?',
          title: 'Bevestig statuswijziging'
        },
        C4013: {
          description:
            'Weet je zeker dat je wilt annuleren? De voortgang van de maatwerkaanvraag zal niet opgeslagen worden.',
          title: 'Bevestig het annuleren van het aanmaken/updaten van de maatwerkaanvraag'
        },
        C4014: {
          description: 'Weet je zeker dat je deze maatwerk offerte wilt verwijderen?',
          title: 'Bevestig verwijderen maatwerk offerte'
        },
        C4015: {
          description:
            'U staat op het punt de selecties voor deze woning opnieuw in te stellen. Hierdoor kan/moet de deelnemer zijn/haar selectie (opnieuw) maken. Weet u zeker dat u nu wilt herstellen?',
          title: 'Bevestig verwijderen selecties'
        },
        C4016: {
          description: 'Weet je zeker dat je deze productpakketten wilt verwijderen?',
          title: 'Bevestig verwijderen productpakketten'
        },
        C4017: {
          description: 'U staat op het punt de herinnering te versturen. Weet u zeker dat u nu wilt verzenden?',
          title: 'Versturen bevestigen'
        },
        C4018: {
          description:
            'U staat op het punt de uitnodigingsherinnering te versturen. Weet u zeker dat u nu wilt verzenden?',
          title: 'Versturen bevestigen'
        },
        C4019: {
          description:
            'Je wilt de woning casco opgeleverd hebben. Weet je zeker dat je de casco oplevering wilt kiezen?',
          title: 'Bevestig de casco oplevering voor de woning.'
        },
        C4020: {
          description:
            'Je wilt de woning standaard opgeleverd hebben. Weet je zeker dat je de standaard oplevering wilt kiezen?',
          title: 'Bevestig de standaard oplevering voor de woning.'
        },
        C4021: {
          description: 'Je staat op het punt deze aanvraag te voltooien. Weet je zeker dat je door wilt gaan?',
          title: 'Voltooien van aanvraag bevestigen'
        },
        C4022: {
          description:
            'Je staat op het punt de productsets onder te verdelen in categorieen. Productsets zonder aangewezen categorie zullen niet getoond worden in de configuratie. Weet je het zeker?',
          title: 'Productsets in categorieen onderverdelen'
        },
        C4023: {
          description: 'Je staat op het punt dit bestand te verwijderen. Weet je het zeker?',
          title: 'Bestand verwijderen bevestigen'
        },
        C4024: {
          description:
            'Je staat op het punt de aanvraag voor 3D tekeningen te annuleren, de koper wordt hiervan op de hoogte gesteld. Weet je zeker dat je door wilt gaan?',
          title: 'Bevestig annuleren van aanvraag'
        },
        C4025: {
          description:
            'Je staat op het punt om het dossier (nogmaals) naar de woning te sturen. Vul alle ontvangers in. Eerder verstuurde links om het dossier te downloaden zullen inactief worden.',
          title: 'Bevestig verzenden dossier'
        },
        C4026: {
          description:
            'Let op: Als je een maatwerk offerte aanvraagt voor de klant, worden er kosten berekend voor de klant die zichtbaar worden, en krijgt hij een bevestigingsmail van deze aanvraag. Weet je zeker dat je wilt doorgaan?',
          title: 'Aanvraag voor maatwerk offerte bevestigen'
        },
        C4027: {
          description:
            'Let op: Als je een technische tekening afwijst betekent dit dat de huidige versie van de maatwerk offerte niet juist is. De status van de maatwerk offerte zal wijzigen naar "wijzigingsverzoek" zodat de showroom adviseur wijzigingen kan aanbrengen in een nieuwe offerte. Geef hieronder aan wat er gewijzigd dient te worden:',
          title: 'Technische tekening afwijzen'
        },
        C4028: {
          description: 'Weet je zeker dat je deze activiteit voor deze productset wilt verwijderen?',
          title: 'Bevestig verwijderen activiteit'
        },
        C4029: {
          description: 'Weet je zeker dat je deze activiteitsjabloon wilt verwijderen?',
          title: 'Bevestig verwijdering van de activiteitssjabloon'
        },
        C4030: {
          description:
            'Je staat op het punt om dit wijzigingsverzoek te annuleren. Weet je zeker dat je wilt doorgaan?',
          title: 'Annuleren van wijzigingsverzoek bevestigen'
        },
        C4031: {
          description:
            'Door op te slaan kan de status van de maatwerkaanvraag veranderen. Weet je zeker dat je door wilt gaan?',
          title: 'Bevestig maatwerkaanvraag'
        },
        C4032: {
          description: 'Weet je zeker dat je een afspraak in de toekomst wilt verwijderen?',
          title: 'Afspraak verwijderen bevestigen'
        },
        C4033: {
          description:
            'Bij het voltooien van de maatwerkaanvraag worden er kosten in rekening gebracht voor de klant, in tegenstelling tot de knop "maatwerkaanvraag annuleren". Weet je zeker dat je de maatwerkaanvraag wilt voltooien?',
          title: 'Maatwerkaanvraag voltooien'
        },
        C4034: {
          description: 'Weet je zeker dat je de aangewezen showroomspecialist wilt wijzigen?',
          title: 'Wijziging van showroomspecialist bevestigen'
        },
        C4035: {
          description:
            'Weet je zeker dat je de maatwerk aanvraag wilt delen met de klant? Als het al gedeeld is met de klant zal deze actie het ongedaan maken.',
          title: 'Bevestig delen (ongedaan maken) van maatwerkaanvraag'
        },
        C4036: {
          description:
            'Weet je zeker dat je het bouwnummer wil forceren? Er zijn kosten gemaakt voor diensten. Bij het forceren van het bouwnummer zal het worden gewijzigd in STANDAARD+ of CASCO+ omdat er diensten zijn afgenomen.',
          title: 'Let op! Bouwnummer bevat kosten voor diensten.'
        },
        C4037: {
          description: 'Weet je zeker dat je deze stijlen wilt verwijderen?',
          title: 'Bevestig verwijderen stijlen'
        },
        C4038: {
          description: 'Weet je zeker dat je deze merken wilt verwijderen?',
          title: 'Bevestig verwijderen merken'
        },
        C4039: {
          description:
            'Weet je zeker dat je designpakketten voor de prijsvarianten wilt genereren? Instellingen voor niet-standaard designpakketten worden overschreven.',
          title: 'Bevestig het genereren van designpakketten voor de prijsvarianten'
        },
        C4040: {
          description: 'Weet je zeker dat je deze stijl wilt verwijderen van dit project?',
          title: 'Bevestig verwijderen van een stijl voor project'
        },
        C4041: {
          description: 'Weet je zeker dat je dit merk wilt verwijderen van dit project?',
          title: 'Bevestig verwijderen van een merk voor project'
        },
        C4042: {
          description:
            'Weet je zeker dat je de geselecteerde stijlen voor dit project wilt bijwerken? Houd er rekening mee dat de designpakketten (her)genereren moeten worden in het tabblad "Designpakketten" om designpakketten aan te maken en/of te verwijderen.',
          title: 'Bevestig het updaten van de geselecteerde stijlen voor dit project'
        },
        C4043: {
          description:
            'Weet je zeker dat je de geselecteerde merken voor dit project wilt bijwerken? Houd er rekening mee dat de designpakketten (her)genereren moeten worden in het tabblad "Designpakketten" om designpakketten aan te maken en/of te verwijderen.',
          title: 'Bevestig het updaten van de geselecteerde merken voor dit project'
        }
      },
      error: {
        E1101: {
          description: 'De selectienaam Standaard bestaat al in deze woning',
          title: 'Fou bij bevestigen standaard selectie'
        },
        E1102: {
          description:
            'Het is niet mogelijk om de productset te verwijderen omdat deze wordt gebruikt in een open project.',
          title: 'Fout bij verwijderen productset'
        },
        E1108: {
          description: 'De projectstatus staat een maatwerkaanvraag niet toe',
          title: 'Fout bij maatwerkaanvraag'
        },
        E1109: {
          description: 'De status van deze woning staat een maatwerkaanvraag niet toe',
          title: 'Fout bij maatwerkaanvraag'
        },
        E1110: {
          description:
            'De verwijderde eigenschap(pen) is/zijn nog nog gebruikt in een of meer productsetten. Leeg de waardes van deze eigenschappen in de desbetreffende productset(ten) voordat je deze eigenschap(pen) hier kunt verwijderen.',
          title: 'Fout tijdens verwijderen van eigenschap(pen) voor deze productset groep'
        },
        E1103: {
          description:
            'De productset kan niet worden verwijderd omdat het een standaard productset is in een designpakket.',
          title: 'Fout bij verwijderen productset'
        },
        E1104: {
          description: 'Alleen bij een offerte op maat kunt u een aanvraag voor een technische tekening annuleren',
          title: 'Fout bij het annuleren van de aanvraag voor een technische tekening'
        },
        E1105: {
          description: 'Er is iets misgegaan bij het verwijderen van de activiteitensjabloon.',
          title: 'Fout bij het verwijderen van de activiteitsjabloon'
        },
        E1106: {
          description: 'Er is iets misgegaan bij het aanmaken van de activiteitstemplate',
          title: 'Fout bij het aanmaken van activiteitstemplate'
        },
        E1107: {
          description: 'Er is iets misgegaan bij het bijwerken van de naam van de activiteitstemplate',
          title: 'Fout bij het bijwerken van de naam van de activiteitstemplate'
        },
        E1111: {
          description: 'Er is iets misgegaan bij het annuleren van het wijzigingsverzoek voor de maatwerkaanvraag',
          title: 'Fout bij het annuleren van een wijzigingsverzoek voor een maatwerkaanvraag'
        },
        E1112: {
          description: 'Er is iets misgegaan bij het maken van een afspraakdatum',
          title: 'Fout bij het maken van afspraak Datum'
        },
        E1113: {
          description: 'Er is iets misgegaan bij het verwijderen van een afspraakdatum',
          title: 'Fout bij het verwijderen van afspraak Datum'
        },
        E1114: {
          description: 'Er is iets misgegaan tijdens het bijwerken van de datum van afspraak',
          title: 'Fout bij het bewerken van afspraak Datum'
        },
        E1115: {
          description:
            'Je kunt geen maatwerkaanvraag delen als er afspraken gepland staan in de toekomst. Werk de afspraken bij of verwijder ze.',
          title: 'Fout bij het bijwerken van de maatwerkaanvraag'
        },
        E1117: {
          description: 'Er is iets misgegaan tijdens het bijwerken van de status van de maatwerkaanvraag',
          title: 'Fout bij het bijwerken van status van maatwerkaanvraag'
        },
        E1118: {
          description: 'Er is geen categoriepagina gevonden die bij deze productset hoort',
          title: 'Fout bij het ophalen van categoriepagina'
        },
        E1119: {
          description: 'Er kon geen categoriepagina gevonden worden die bij deze categorie hoort',
          title: 'Fout bij ophalen categoriepagina'
        },
        E4001: {
          description: 'Er is iets mis gegaan tijdens het inloggen',
          title: 'Fout tijdens inloggen'
        },
        E4101: {
          description: 'Er is iets mis gegaan bij het ophalen van de projecten',
          title: 'Fout tijdens ophalen van projecten'
        },
        E4102: {
          description: 'Er is iets mis gegaan bij het ophalen van de stijlen',
          title: 'Fout tijdens ophalen van stijlen'
        },
        E4103: {
          description: 'Er is iets mis gegaan bij het verwijderen van de stijl',
          title: 'Fout tijdens verwijderen van stijl'
        },
        E4104: {
          description: 'Er is iets mis gegaan bij het opzoeken van de bijbehorende stijlpagina',
          title: 'Fout tijdens opzoeken van stijlpagina'
        },
        E4105: {
          description: 'Er kon geen stijlpagina gevonden worden die bij deze stijl hoort.',
          title: 'Fout tijdens opzoeken van stijlpagina'
        },
        E4106: {
          description: 'Er is iets mis gegaan bij het ophalen van de projectdetails',
          title: 'Fout tijdens ophalen van projectdetails'
        },
        E4107: {
          description: 'Er is iets fout gegaan bij het publiceren van het project',
          title: 'Fout tijdens het publiceren van het project'
        },
        E4108: {
          description: 'Er is iets fout gegaan bij het depubliceren van het project',
          title: 'Fout tijdens het depubliceren van het project'
        },
        E4109: {
          description: 'Er is iets fout gegaan bij het sluiten van het project',
          title: 'Fout tijdens het sluiten van het project'
        },

        E4110: {
          description: 'Er is iets fout gegaan bij het heropenen van het project',
          title: 'Fout tijdens het heropenen van het project'
        },
        E4111: {
          description: 'Er is iets mis gegaan bij het aanmaken van de nieuwe stijl',
          title: 'Fout tijdens het aanmaken van de nieuwe stijl'
        },
        E4112: {
          description: 'Er is iets mis gegaan bij het ophalen van de bouwnummers',
          title: 'Fout tijdens ophalen van bouwnummers'
        },
        E4113: {
          description: 'Er is iets mis gegaan bij het tonen van de preview',
          title: 'Fout tijdens tonen van de preview'
        },
        E4114: {
          description: 'Er is iets mis gegaan bij het ophalen van de ruimtes',
          title: 'Fout tijdens ophalen van ruimtes'
        },
        E4115: {
          description: 'Er is iets mis gegaan bij het verwijderen van de ruimte',
          title: 'Fout tijdens het verwijderen van ruimte'
        },
        E4116: {
          description: 'Er is iets mis gegaan bij het aanmaken van de nieuwe ruimte',
          title: 'Fout tijdens het aanmaken van de nieuwe ruimte'
        },
        E4117: {
          description: 'Er is iets mis gegaan bij het ophalen van de stijlen van het project',
          title: 'Fout tijdens ophalen van de stijlen van het project'
        },
        E4118: {
          description: 'Er is iets mis gegaan bij het updaten van de geselecteerde stijlen voor dit project',
          title: 'Fout tijdens updaten stijlen'
        },
        E4120: {
          description: 'Er is iets mis gegaan bij het ophalen van de designpakket',
          title: 'Fout tijdens ophalen van designpakket'
        },
        E4121: {
          description: 'Er is iets mis gegaan bij het aanmaken van de nieuwe ruimte',
          title: 'Fout tijdens het aanmaken van de nieuwe ruimte'
        },
        E4122: {
          description: 'Er is iets mis gegaan bij het ophalen van de designpakketten',
          title: 'Fout tijdens ophalen van designpakketten'
        },
        E4123: {
          description: 'Er is iets mis gegaan bij het verwijderen van het designpakket',
          title: 'Fout tijdens het verwijderen van designpakket'
        },
        E4124: {
          description: 'Er is iets mis gegaan bij het ophalen van de designpakketten',
          title: 'Fout tijdens ophalen van designpakketten'
        },
        E4125: {
          description: 'Er is iets mis gegaan bij het bewerken van het designpakket',
          title: 'Fout tijdens het bewerken van het designpakket'
        },
        E4126: {
          description:
            'Het is niet mogelijk een designpakket aan te maken met dezelfde eigenschappen als een al bestaand designpakket',
          title: 'Fout tijdens het aanmaken van het design'
        },
        E4127: {
          description:
            'De applicatie kon het te bewerken designpakket niet vinden. Bent u naar deze pagina genavigeerd met een opgeslagen adres of favoriet?',
          title: 'Designpakket niet gevonden'
        },
        E4129: {
          description: 'Er is iets mis gegaan bij het ophalen van de ruimte',
          title: 'Fout tijdens ophalen van ruimte'
        },
        E4130: {
          description: 'Er is iets mis gegaan bij het bewerken van de ruimte',
          title: 'Fout tijdens het bewerken van de ruimte'
        },
        E4131: {
          description: 'Er is iets misgegaan tijdens het verzenden van de e-mail.',
          title: 'Fout tijdens verzenden email'
        },
        E4132: {
          description: 'Er is iets mis gegaan bij het verwijderen van het project',
          title: 'Fout tijdens het verwijderen van project'
        },
        E4133: {
          description: 'Er is iets mis gegaan bij het resetten van de designpakket selectie',
          title: 'Fout tijdens het resetten van selectie'
        },
        E4134: {
          description: 'Er is iets mis gegaan bij het opzoeken van het bijbehorende designpakket',
          title: 'Fout tijdens opzoeken van designpakket'
        },
        E4135: {
          description: 'Er kon geen designpakket gevonden worden die bij dit pakket hoort.',
          title: 'Fout tijdens opzoeken van designpakket'
        },
        E4140: {
          description: 'Er is iets mis gegaan bij het ophalen van de merken',
          title: 'Fout tijdens ophalen van merken'
        },
        E4141: {
          description: 'Er is iets mis gegaan bij het verwijderen van het merk',
          title: 'Fout tijdens verwijderen van merk'
        },
        E4142: {
          description: 'Er is iets mis gegaan bij het opzoeken van de bijbehorende stijlpagina',
          title: 'Fout tijdens opzoeken van stijlpagina'
        },
        E4143: {
          description: 'Er kon geen stijlpagina gevonden worden die bij dit merk hoort.',
          title: 'Fout tijdens opzoeken van stijlpagina'
        },
        E4144: {
          description: 'Er is iets mis gegaan bij het ophalen van de merken van het project',
          title: 'Fout tijdens ophalen van de merken van het project'
        },
        E4145: {
          description: 'Er is iets mis gegaan bij het updaten van het geselecteerde merken',
          title: 'Fout tijdens updaten van merken'
        },
        E4150: {
          description: 'Er is iets mis gegaan bij het ophalen van de productpakketten',
          title: 'Fout tijdens ophalen van productpakketten'
        },
        E4151: {
          description: 'Er is iets misgegaan bij het ophalen van de gerelateerde productpakketpagina',
          title: 'Fout bij ophalen van productpakketpagina'
        },
        E4152: {
          description: 'Er is geen productpakketpagina gevonden die bij dit productpakket hoort',
          title: 'Fout bij ophalen van productpakketpagina'
        },
        E4153: {
          description: 'Er is iets mis gegaan bij het ophalen van de productpakketten van het project',
          title: 'Fout tijdens ophalen van de productpakketten van het project'
        },
        E4154: {
          description: 'Er is iets mis gegaan bij het toewijzen van de productpakketten van het project',
          title: 'Fout tijdens toewijzen van de productpakketten van het project'
        },
        E4155: {
          description: 'Er is iets mis gegaan bij het intrekken van de productpakketten van het project',
          title: 'Fout tijdens intrekken van de productpakketten van het project'
        },
        E4900: {
          description: 'De functie die u probeert te openen, is nog niet geïmplementeerd',
          title: 'Niet geïmplementeerd'
        },
        E4156: {
          description:
            'Er is iets misgegaan bij het ophalen van de restricties van de productsets voor het designpakket',
          title: 'Fout bij het ophalen van restricties voor productsets voor het designpakket'
        },
        E4157: {
          description: 'Er is iets misgegaan bij het ophalen van de standaard productsets',
          title: 'Fout bij het ophalen van standaard productsets'
        },
        E4158: {
          description: 'Er is iets misgegaan bij het ophalen van de gerelateerde productcategorie pagina',
          title: 'Fout bij ophalen van productpakketpagina'
        },
        E4159: {
          description: 'Er is geen productcategorie pagina gevonden die bij deze productcategorie hoort',
          title: 'Fout bij ophalen van productcategorie pagina'
        },
        E4160: {
          description: 'Er is iets mis gegaan bij het updaten van het huidige productpakket',
          title: 'Fout bij bijwerken productpakket'
        },
        E4161: {
          description: 'Er is iets mis gegaan bij het updaten van de productset groep',
          title: 'Fout bij bijwerken productset groep'
        },
        E4162: {
          description: 'Er is iets mis gegaan bij het aanmaken van een nieuwe categorie',
          title: 'Fout bij aanmaken categorie'
        },
        E4163: {
          description: 'Er is iets mis gegaan bij het aanmaken van een nieuw productpakket',
          title: 'Fout bij aanmaken productpakket'
        },
        E4164: {
          description: 'Er is iets mis gegaan bij het verwijderen van een categorie',
          title: 'Fout bij verwijderen categorie'
        },
        E4165: {
          description: 'Er is iets mis gegaan bij het verwijderen van een productset',
          title: 'Fout bij verwijderen productset'
        },
        E4166: {
          description: 'Er is iets misgegaan bij het ophalen van de gerelateerde productset pagina',
          title: 'Fout bij ophalen van productset pagina'
        },
        E4167: {
          description: 'Er is geen productset pagina gevonden die bij deze productset hoort',
          title: 'Fout bij ophalen van productset pagina'
        },
        E4168: {
          description: 'Er is iets mis gegaan bij het updaten van de marges',
          title: 'Fout bij het bijwerken van de marges'
        },
        E4169: {
          description: 'Er is iets mis gegaan bij het aanmaken van een nieuwe productset',
          title: 'Fout bij het aanmaken van een productset'
        },
        E4170: {
          description: 'Er is iets mis gegaan bij het updaten van een productset',
          title: 'Fout bij het updaten van een productset'
        },
        E4171: {
          description:
            'Er is iets mis gegaan bij het updaten van een productset voor het markeren als favoriet/aanbevolen',
          title: 'Fout bij het updaten van een productset'
        },
        E4172: {
          description: 'Er is iets mis gegaan bij het uitnodigen van de geselecteerde woningen',
          title: 'Fout bij het uitnodingen van woningen'
        },
        E4173: {
          description: 'Er is iets mis gegaan bij het kopiëren van een nieuw productset',
          title: 'Fout bij kopiëren productset'
        },
        E4174: {
          description: 'Er is iets mis gegaan bij het opvragen van de 3D tekeningen',
          title: 'Fout bij het ophalen van 3D tekeningen'
        },
        E4175: {
          description: 'Er is iets mis gegaan bij het uploaden van een 3D tekening',
          title: 'Fout bij het uploaden van een bestand'
        },
        E4176: {
          description: 'Er is iets mis gegaan bij het ophalen van de productpakket importen',
          title: 'Fout bij het ophalen van de productpakket importen'
        },
        E4177: {
          description: 'Er is iets mis gegaan bij het uploaden van een productpakket',
          title: 'Fout bij het uploaden van een bestand'
        },
        E4178: {
          description: 'Er is iets mis gegaan bij het ophalen van de 3D tekening aanvragen',
          title: 'Fout bij het ophalen van de 3D tekening aanvragen'
        },
        E4179: {
          description: 'Er is iets mis gegaan bij het ophalen van alle productsetdetails',
          title: 'Fout bij het ophalen van alle productsetdetails'
        },
        E4180: {
          description: 'Er is iets mis gegaan bij het ophalen van de productset groepen',
          title: 'Fout bij ophalen productset groepen'
        },
        E4181: {
          description: 'Er is iets mis gegaan bij het aanmaken van een productset groep',
          title: 'Fout bij aanmaken productset groep'
        },
        E4182: {
          description: 'Er is iets mis gegaan bij het aanpassen van de productset groep',
          title: 'Fout bij aanpassen productset groep'
        },
        E4183: {
          description: 'Er is iets mis gegaan bij het ophalen van de prijzen',
          title: 'Fout bij ophalen van prijzen'
        },
        E4184: {
          description: 'Er is iets mis gegaan bij het updaten van de prijs',
          title: 'Fout bij updaten prijs'
        },
        E4186: {
          description: 'Er is iets mis gegaan bij het aanpassen van de aanvraag voor maatwerk instelling',
          title: 'Fout tijdens aanpassen aanvraag voor maatwerk instelling'
        },
        E4187: {
          description: 'Er is iets mis gegaan bij het ophalen van de details van de bestelling',
          title: 'Fout tijdens ophalen details van bestelling'
        },
        E4188: {
          description: 'Er is iets mis gegaan bij het veranderen van de status van de productset',
          title: 'Fout bij het wijzigen van de status van de productset'
        },
        E4189: {
          description: 'Er is iets misgegaan bij het downloaden van het kostenoverzicht voor dit project',
          title: 'Fout bij downloaden van het kostenoverzicht'
        },
        E4190: {
          description: 'Er is iets mis gegaan bij het ophalen van de details van de aanvraag voor maatwerk',
          title: 'Fout bij ophalen details van aanvraag voor maatwerk'
        },
        E4191: {
          description: 'Er is iets mis gegaan bij het updaten van de aanvraag voor maatwerk',
          title: 'Fout bij updaten van aanvraag voor maatwerk'
        },
        E4192: {
          description: 'Er is iets mis gegaan bij het uploaden van bestanden voor de aanvraag voor maatwerk',
          title: 'Fout bij uploaden bestanden'
        },
        E4193: {
          description: 'Er is iets mis gegaan bij het annuleren van de aanvraag voor maatwerk',
          title: 'Fout bij annuleren van aanvraag voor maatwerk'
        },
        E4194: {
          description: 'Er is iets misgegaan bij het veranderen van de instelling voor de retourprijs',
          title: 'Fout bij het wijzigen van de instelling van de retourprijs'
        },
        E4195: {
          description: 'Er is iets misgegaan bij het downloaden van de maatwerkaanvraag',
          title: 'Fout bij het downloaden van maatwerkaanvraag'
        },
        E4196: {
          description: 'Er is iets misgegaan bij het verwijderen van de maatwerkaanvraag',
          title: 'Fout bij verwijderen maatwerkaanvraag'
        },
        E4197: {
          description: 'Er is iets misgegaan bij het updaten van de prijzen van de geselecteerde productsets',
          title: 'Fout bij updaten productsets'
        },
        E4198: {
          description: 'Er ging iets mis tijdens het kopiëren van een nieuw productpakket',
          title: 'Fout bij het kopiëren van een productpakket'
        },
        E4199: {
          description: 'Er ging iets mis bij het herstellen van de selectie van de woning’',
          title: 'Fout bij herstellen van de selectie voor de woning'
        },
        E4200: {
          description: 'Er is iets misgegaan bij het verwijderen van de productpakket',
          title: 'Fout bij verwijderen productpakket'
        },
        E4201: {
          description: 'Er is iets mis gegaan bij het in-/uitschakelen van de notificaties voor het bouwnummer.',
          title: 'Fout in-/uitschakelen notificaties'
        },
        E4202: {
          description: 'Er is iets misgegaan bij het verzenden van de notificatie.',
          title: 'Fout bij het verzenden van kennisgeving'
        },
        E4203: {
          description: 'Er iets misgegaan bij het bevestigen van de gekozen selectie van de woning.',
          title: 'Fout bij het bevestigen van de selectie'
        },
        E4204: {
          description: 'Er is iets mis gegaan bij het aanmaken van een nieuwe eigenschap.',
          title: 'Fout aanmaken eigenschap'
        },
        E4205: {
          description: 'Er is iets mis gegaan bij het opvragen van de contactaanvragen',
          title: 'Fout bij opvragen contactaanvragen'
        },
        E4206: {
          description: 'Er is iets mis gegaan bij het updaten van de status van deze contactaanvraag.',
          title: 'Fout bij updaten status van contactaanvraag'
        },
        E4207: {
          description: 'Er is iets mis gegaan bij het uploaden van dit bestand',
          title: 'Fout uploaden bestand'
        },
        E4208: {
          description: 'Er is iets mis gegaan bij het verwijderen van dit bestand',
          title: 'Fout verwijderen bestand'
        },
        E4209: {
          description: 'Er is iets misgegaan bij het verzenden van het dossier.',
          title: 'Fout bij verzenden van dossier'
        },
        E4210: {
          description: 'Er is iets mis gegaan bij het annuleren van een verzoek',
          title: 'Fout bij het annuleren van een verzoek'
        },
        E4211: {
          description: 'Er is iets mis gegaan bij het downloaden van het projectoverzicht',
          title: 'Fout bij downloaden projectoverzicht'
        },
        E4212: {
          description: 'Er is iets mis gegaan bij het ophalen van de details van deze woning',
          title: 'Fout bij ophalen details'
        },
        E4214: {
          description: 'Er is iets misgegaan bij het aanmaken van een maatwerkaanvraag',
          title: 'Fout bij aanmaken maatwerkaanvraag'
        },
        E4215: {
          description: 'Er is iets misgegaan bij het ophalen van de openstaande vraag/actie voor dit bouwnummer',
          title: 'Fout bij ophalen externe vraag/actie'
        },
        E4216: {
          description: 'Er is iets misgegaan bij het aanmaken van een externe vraag/actie',
          title: 'Fout bij aanmaken externe vraag/actie'
        },
        E4217: {
          description: 'Er is iets misgegaan bij het updaten van de externe vraag/actie',
          title: 'Fout bij updaten externe vraag/actie'
        },
        E4218: {
          description: 'Er is iets misgegaan bij het voltooien van de externe vraag/actie',
          title: 'Fout bij voltooien externe vraag/actie'
        },
        E4221: {
          description:
            'Er is iets mis gegaan tijdens het aanpassen van de instelling om maatwerk onder te verdelen in ruimtes',
          title: 'Fout aanpassen instelling om maatwerk onder te verdelen in ruimtes'
        },
        E4222: {
          description: 'Er is iets mis gegaan tijdens het updaten van de lijst met e-mailadressen voor het dossier',
          title: 'Fout updaten van e-mailadressen voor dossier'
        },
        E4223: {
          description: 'Er is iets mis gegaan tijdens het aanmaken van een nieuw type tegel',
          title: 'Fout aanmaken tegel'
        },
        E4224: {
          description: 'Er is iets mis gegaan tijdens het ophalen van alle installatie activiteiten voor dit project',
          title: 'Fout ophalen activiteiten'
        },
        E4225: {
          description: 'Er is iets mis gegaan tijdens het updaten van installatiekosten',
          title: 'Fout updaten installatiekosten'
        },
        E4226: {
          description: 'Er is een fout opgetreden bij het ophalen van de technische tekeningen',
          title: 'Fout bij het ophalen van de technische tekeningen'
        },
        E4227: {
          description: 'Er is een fout opgetreden bij het ophalen van de beschikbare tekenaars',
          title: 'Fout bij het ophalen van de tekenaar'
        },
        E4228: {
          description: 'Er is een fout opgetreden bij uploaden van de technische tekening',
          title: 'Fout bij het uploaden van technische tekening'
        },
        E4229: {
          description: 'Er is een fout opgetreden bij downloaden van de technische tekening',
          title: 'Fout bij het downloaden van technische tekening'
        },
        E4230: {
          description:
            'Er is een fout opgetreden bij het toewijzen van een tekenaar voor deze aanvraag voor 3D tekening',
          title: 'Fout bij het toewijzen van een tekenaar'
        },
        E4231: {
          description:
            'Er is een fout opgetreden bij het toewijzen van een tekenaar voor deze aanvraag voor technische tekening',
          title: 'Fout bij het toewijzen van een tekenaar'
        },
        E4232: {
          description: 'Er is een fout opgetreden bij het importeren van het activiteitensjabloon',
          title: 'Fout bij het importeren van sjabloon'
        },
        E4233: {
          description: 'Er ging iets mis tijdens het ophalen van de activiteiten',
          title: 'Fout ophalen activiteiten'
        },
        E4234: {
          description: 'Er is iets misgegaan bij het ophalen van de activiteitstemplates',
          title: 'Fout bij het ophalen van de activiteitstemplates'
        },
        E4235: {
          description:
            'Er is een fout opgetreden bij het downloaden van de technische tekening omdat deze niet bestaat',
          title: 'Fout bij downloaden technische tekening'
        },
        E4236: {
          description: 'Er is iets misgegaan bij het bijwerken van de maatwerkaanvraag',
          title: 'Fout bij bijwerken van maatwerkaanvraag'
        },
        E4237: {
          description: 'Er is iets misgegaan bij het downloaden van het sjabloonbestand voor importactiviteiten',
          title: 'Fout bij het downloaden van het sjabloonbestand voor importactiviteiten'
        },
        E4238: {
          description: `Er is iets misgegaan tijdens het genereren van designpakketten voor een of meerdere prijsvarianten.`,
          title: 'Fout bij het genereren van sommige designpakketten voor prijsvarianten'
        },
        E4239: {
          description: 'Er ging iets mis tijdens het kopiëren van een nieuw merk',
          title: 'Fout bij het kopiëren van een merk'
        },
        E4240: {
          description: 'Er ging iets mis tijdens het kopiëren van een nieuwe stijl',
          title: 'Fout bij het kopiëren van een stijl'
        },
        E4241: {
          description: 'Er is iets misgegaan tijdens het ophalen van de categorieën',
          title: 'Fout bij het ophalen categorieën'
        },
        E4242: {
          description: 'Er is iets misgegaan bij het ophalen van de contactaanvraag',
          title: 'Fout bij ophalen contactaanvraag'
        },
        E4243: {
          description: "Er is iets misgegaan bij het aanmaken van de notitie",
          title: "Fout bij aanmaken notitie"
        },
        E4244: {
          description: "Er is iets misgegaan bij het downloaden van het product package importbestand",
          title: "Fout bij downloaden product package importbestand"
        },

        EB1111: {
          description:
            'Er is al een andere productset gemarkeerd als standaard voor prijsvariant "{{PRICEVARIANT}}". Verwijder de link tussen de productset en de prijsvariant voor je deze product set kan markeren als standaard voor prijsvariant "{{PRICEVARIANT}}".',
          title: 'Fout bij het markeren als standaard voor een prijsvariant'
        },
        EB1112: {
          description:
            'Sommige designpakketten konden niet worden gegenereerd. Er ontbreken pakketkeuzes voor prijsvarianten {{MISSING_PRESELECTED}}. Stel de pakketkeuzes in voor de prijsvarianten van deze stijlen/merken en probeer het opnieuw.',
          title: 'Fout bij het genereren van sommige designpakketten voor prijsvarianten'
        },
        EB1113: {
          description: `Er zijn nog een aantal standaard designpakketten die prijsvarianten missen: "{{MISSING_PROJECT_VARIATION_DESIGNPACKAGES}}"`,
          title: 'Fout tijdens het publiceren van het project'
        }
      },
      notification: {
        N4001: {
          description:
            'Een project kan pas gepubliceerd worden als voor alle woningen geldt dat er minimaal één Design Package per ruimte geconfigureerd is. Dit is momenteel niet het geval.',
          title: 'Publiceren niet mogelijk'
        },
        N4002: {
          description: 'Er zijn momenteel geen stijlen geselecteerd op het project.',
          title: 'Geen stijlen geselecteerd op project'
        },
        N4003: {
          description: 'Er zijn momenteel geen ruimtes aangemaakt op het project.',
          title: 'Geen ruimtes aangemaakt op project'
        },
        N4004: {
          description:
            'Deze ruimte heeft nog designpakketten. Het is noodzakelijk deze pakketten eerst te verwijderen voor deze ruimte verwijderd kan worden',
          title: 'Ruimte heeft designpakketten'
        },
        N4005: {
          description:
            "U heeft waarschijnlijk net op een knop gedrukt. De 'feature' achter deze knop is echter nog niet af.",
          title: 'Nog niet geïmplementeerd'
        },
        N4007: {
          description: 'De applicatie heeft succesvol verzocht een email te versturen.',
          title: 'Email verzoek verzonden'
        },
        N4008: {
          description:
            'De applicatie heeft een externe preview link gemaakt en deze gekopieerd (Ctrl+C) naar het klembord. Deze link is nu beschikbaar om te plakken (Ctrl+V) waar u wilt en is geldig voor 14 dagen.',
          title: 'Externe preview link'
        },
        N4009: {
          description: 'Er zijn momenteel geen merken geselecteerd op het project.',
          title: 'Geen merken geselecteerd op project'
        },
        N4010: {
          description: 'Er zijn momenteel geen productpakketten geselecteerd op het project.',
          title: 'Geen productpakketten geselecteerd op project'
        }
      },
      upload: {
        title: 'Bestand uploaden',
        uploadButton: 'Uploaden',
        errors: {
          maxFileSize: 'Bestand overschrijdt de maximum grootte van {MAX} MB',
          invalidType: 'Bestandstype niet toegestaan',
          required: 'Bestand is verplicht',
          duplicateName: 'Deze naam bestaat al',
          invalidName: 'De naam bevat foutieve karakters',
          maxNameSize: 'De naam heeft meer karakters dan het maximaal toegestane aantal ({MAX})',
          invalidEmails: 'Een of meer emailadressen hebben niet het juiste formaat.'
        }
      }
    },
    edit: 'Wijzigen',
    copy: 'Kopiëren',
    forms: {
      requiredFields: '* verplicht veld',
      save: 'Opslaan',
      search: 'Zoeken',
      import: 'Import ruimtes',
      textarea: {
        maxLength: 'The inhoud mag een maximale lengte van 1000 tekens hebben.'
      }
    },
    login: 'Inloggen',
    signOut: 'Uitloggen',
    redirecting: 'Gelieve even te wachten. U wordt omgeleid.',
    no: 'Nee',
    optional: 'optioneel',
    readonly: 'niet wijzigbaar',
    remove: 'Verwijderen',
    shellOnly: 'Casco',
    standard: 'Standaard',
    preselected: 'Selecteer een pakketkeuze voor {{CATEGORYNAME}}',
    yes: 'Ja',
    validationMoney: 'Moet een positief (komma)getal zijn, kleiner dan één miljoen',
    validationDecimalPlaces2: 'Moet een positief getal zijn met maximaal 2 decimale posities',
    validationPercentage: 'Moet een percentage tussen de 0% en 100% zijn',
    validationAreaMeasure: 'Moet een positief (komma)getal zijn',
    validationRequired: 'Is een verplicht veld',
    validationDuplicateName: 'Deze naam bestaat al',
    validationLengthMeasure: 'Moet een positief (komma)getal zijn, kleiner dan één miljoen',
    returnPrice: 'Retourprijs',
    defaultProductSets: 'Gekozen standaard productsets gebruiken voor project',
    editContent: 'Content bewerken',
    save: 'Opslaan',
    generateQR: 'Genereer QR code',
    toasts: {
      actionSuccessful: 'De actie was succesvol'
    }
  },
  header: {
    logout: 'Uitloggen',
    projects: 'Projecten',
    styles: 'Stijlen',
    brands: 'Merken',
    requests: 'Aanvragen',
    drawings: 'Tekeningen',
    technicalDrawings: 'Technische tekeningen',
    customOffers: 'Maatwerk',
    contactRequests: 'Contact',
    tools: 'Tools',
    welcome: 'Welkom',
    products: 'Producten',
    proposition: 'Propositie',
    application: 'Applicatie',
    categories: 'Productset groepen',
    activities: 'Sjablonen voor activiteiten',
    masterProductPackage: 'Beheer hoofdproductpakket'
  },
  logout: {
    text: 'U bent nu uitgelogd, en als u wilt kunt opnieuw inloggen of het dit venster sluiten.',
    title: 'Uitgelogd'
  },
  project: {
    list: {
      noDataText: 'Geen projecten gevonden',
      searchPlaceholder: 'Projectnaam, projectnummer, bouwnummer of projectontwikkelaar',
      table: {
        lastImportedOn: 'Geïmporteerd',
        name: 'Naam',
        projectDeveloper: 'Projectontwikkelaar',
        realEstateCount: 'Woningen',
        realEstateUninvitedCount: 'Niet uitgenodigd',
        requestForQuoteCount: 'Offertes',
        status: 'Status',
        projectType: 'Propositie'
      },
      title: 'Projecten',
      toProject: 'Naar project',
      hideClosed: 'Verberg gesloten projecten'
    },
    status: {
      closed: 'Gesloten',
      ended: 'Geëindigd',
      open: 'Gestart',
      preview: 'Concept',
      unknown: 'Onbekend'
    },
    proposition: {
      DE_BADKAMERWERELDEN: 'Stijlen',
      MERKENPROPOSITIE: 'Merken',
      PRODUCTENPROPOSITIE: 'Producten'
    }
  },
  projectDesignPackage: {
    create: {
      backToOverview: 'Terug naar overzicht',
      nameSelect: 'Selecteer een prijsvariant',
      positionToolTip:
        'Vul een getal in om de positie te bepalen. De customer portal laat de design pakketten zien op basis van deze positie/volgorde.',
      roomSelect: 'Selecteer een ruimte',
      styleSelect: 'Selecteer een stijl',
      brandSelect: 'Selecteer een merk',
      title: 'Nieuw designpakket toevoegen',
      configure: 'Configureer',
      productCombinationForRoom: 'Activeer productcombinaties voor deze ruimte',
      productPackageSelect: 'Selecteer een productpakket',
      standardCheckBox: 'Standaard',
      standardToolTip: 'Indien geselecteerd, wordt deze standaard gedefinieerd.'
    },
    edit: {
      nameSelect: 'Geselecteerde prijsvariant',
      nameToolTip:
        'Het is helaas niet mogelijk om een andere prijsvariant te selecteren voor een bestaand designpakket. Het is wel mogelijk om dit designpakket te verwijderen en een nieuwe aan te maken.',
      roomSelect: 'Geselecteerde ruimte',
      roomToolTip:
        'Het is helaas niet mogelijk om een andere ruimte te selecteren voor een bestaand designpakket. Het is wel mogelijk om dit designpakket te verwijderen en een nieuwe aan te maken.',
      styleSelect: 'Geselecteerde stijl',
      styleToolTip:
        'Het is helaas niet mogelijk om een andere stijl te selecteren voor een bestaand designpakket. Het is wel mogelijk om dit designpakket te verwijderen en een nieuwe aan te maken.',
      brandSelect: 'Geselecteerd merk',
      brandToolTip:
        'Het is helaas niet mogelijk om een ander merk te selecteren voor een bestaand designpakket. Het is wel mogelijk om dit designpakket te verwijderen en een nieuwe aan te maken.',
      title: 'Designpakket bewerken',
      standardToolTip:
        'Het is helaas niet mogelijk om standaard te wijzigen voor een bestaand designpakket. Het is wel mogelijk om dit designpakket te verwijderen en een nieuwe aan te maken.',
      productPackageSelect: 'Selecteer een productpakket',
      productPackageToolTip:
        'Het is helaas niet mogelijk om een ander productpakket te selecteren voor een bestaand designpakket. Het is wel mogelijk om dit designpakket te verwijderen en een nieuwe aan te maken.'
    },
    errors: {
      name: {
        alphaNumSpace: 'Prijsvariant mag alleen alphanumerieke karakters bevatten.',
        maxLength: 'Prijsvariant heeft een maximum lengte van 20 karakters.',
        required: 'Prijsvariant is een verplicht veld.'
      },
      position: {
        integer: 'Positie moet een geheel getal zijn.',
        minValue: 'Positie moet nul of groter zijn.',
        required: 'Positie is een verplicht veld.'
      },
      invalidCurrency: 'Ongeldige munteenheid',

      room: {
        required: 'Room is een verplicht veld.'
      },
      style: {
        required: 'Style is een verplicht veld.'
      },
      brand: {
        required: 'Brand is een verplicht veld.'
      },
      totalPrice: {
        money: 'Totaalprijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Totaalprijs is een verplicht veld.'
      },
      productPackage: {
        required: 'Product package is een verplicht veld.'
      },
      productsAndInstallationMaterialsPrice: {
        money: 'Producten en installatiematerialen prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Producten en installatiematerialen prijs is een verplicht veld.'
      },
      coordinationCommissionPrice: {
        money: 'Coördinatie en provisie prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Coördinatie en provisie prijs is een verplicht veld.'
      },
      coordinationCommissionInstallerPrice: {
        money:
          'Coördinatie en provisiekosten installateur moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Coördinatie en provisiekosten installateur is een verplicht veld.'
      },
      coordinationCommissionBuilderPrice: {
        money:
          'Coördinatie en provisiekosten ontwikkelaar moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Coördinatie en provisiekosten ontwikkelaar is een verplicht veld.'
      },
      installationConstructionPrice: {
        money: 'Installatie & bouwkundige prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Installatie & bouwkundige prijs is een verplicht veld.'
      },
      tilesAndFastenersPrice: {
        money: 'Tegels en bevestigingsmaterialen prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Tegels en bevestigingsmaterialen prijs is een verplicht veld.'
      },
      cvInstallationPrice: {
        money: 'Installatie CV prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Installatie CV prijs is een verplicht veld.'
      },
      eInstallationPrice: {
        money: 'Installatie E prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Installatie E prijs is een verplicht veld.'
      },
      wInstallationPrice: {
        money: 'Installatie W prijs moet een positief (komma)getal zijn, kleiner dan één miljoen.',
        required: 'Installatie W prijs is een verplicht veld.'
      },
      combinations: {
        selectedCombination: 'Selecteer minimaal één productcombinatie.'
      }
    },
    labels: {
      bath: 'Bad',
      toilet: 'Toilet',
      variant: 'Prijsvariant',
      position: 'Positie',
      totalPrice: 'Totaalprijs',
      fromPrice: 'Vanafprijs',
      room: 'Ruimte',
      layout: 'Layout',
      shellOnly: 'Casco',
      style: 'Stijl',
      xl: 'Vergroot',
      brand: 'Merk',
      productPackage: 'Productpakket',
      standard: 'Standaard',
      productCombinations: 'Productcombinaties',
      productsAndInstallationMaterialsPrice: 'Producten en installatiematerialen prijs',
      coordinationCommissionPrice: 'Coördinatie en provisie prijs',
      coordinationCommissionInstallerPrice: 'Coördinatie en provisiekosten Installateur',
      coordinationCommissionBuilderPrice: 'Coördinatie en provisiekosten Ontwikkelaar',
      installationConstructionPrice: 'Bouwkundige prijs',
      tilesAndFastenersPrice: 'Tegels en bevestigingsmaterialen prijs',
      cvInstallationPrice: 'Installatie CV prijs',
      eInstallationPrice: 'Installatie E prijs',
      wInstallationPrice: ' Installatie W prijs',
      designPackageName: 'Naam',
      restrictionKeys: {
        WIDTH: 'Maximale breedte',
        LENGTH: 'Maximale lengte',
        DEPTH: 'Maximale diepte',
        HEIGHT: 'Maximale hoogte',
        POSITION_TAPS: 'Inbouwkranen mogelijk',
        ELECTRICITY: 'Elektriciteit voor spiegel',
        ALLOW_SHOWER_TOILET: 'Douchetoilet mogelijk',
        BASIC_INCLUDED: 'Standaardcombinatie aanwezig',
        RESERVOIR_TYPE: 'Inbouwreservoir',
        RADIATOR_TYPE: 'Type radiator',
        RADIATOR_CAPACITY: 'Minimum capaciteit in ruimte',
        CORNER_BASIN: 'Hoekbekken',
        ALLOW_BATHTYPE_WHIRLPOOL: 'Whirpool mogelijk',
        ALLOW_BATHTYPE_FREESTANDING: 'Vrijstaand mogelijk',
        ALLOW_DRAINAGE_GUTTER: 'Gootafvoer mogelijk',
        ALLOW_DRAINAGE: 'Douchegoot mogelijk',
        ALLOW_POSITION_TAPS_BUILTIN: 'Inbouw mogelijk',
        MIN_WIDTH: 'Standaard breedte ',
        MIN_LENGTH: 'Standaard lengte ',
        MIN_DEPTH: 'Standaard diepte ',
        IS_SHOWER_DOOR_BETWEEN_WALLS: 'Alleen met nisdeur',
        ALLOWED_SHOWER_ENCLOSURE_TYPE: 'Type douchecabine mogelijk',
        FLOOR_AREA: 'Vloeroppervlak, inclusief douchevloer',
        TOILET_SURROUND_AREA: 'Oppervlakte van toiletombouw',
        TOILET_SURROUND_LINEAR: 'Strekkende meters toiletombouw (m)',
        STANDARD_WALL_TILE_HEIGHT: 'Standaard hoogte wandtegels'
      },
      walls: {
        title: 'Wanden',
        tilesToCeilingLabel: "Stijl / Merk voorselectie tegels tot plafond?",
        tilesToCeilingAnswers:{
          noPreselection: "Geen voorselectie",
          yes: 'Wel tot het plafond',
          no: 'Niet tot het plafond'
        },
        addWall: '+ Wand toevoegen',
        defaultTileLabel: 'Standaard tegel',
        defaultWallTileLabel: 'Geen afwijkende standaardtegel',
        preselectedTileLabel: 'Pakketkeuze voor tegel',
        preselectedWallTileLabel: 'Geen voorgeselecteerde keuze',
        tableHeaders: {
          name: 'Naam',
          area: 'Aantal m2 tegels',
          profileLength: 'Strekkende meters profiel',
          ceilingArea: 'Aantal m2 plafond',
          ceilingLength: 'Strekkende meters plafond',
          defaultAccentTile: 'Standaard tegel',
          preselectedAccentTile: 'Pakketkeuze voor tegel',
          accentWall: 'Accentwand'
        }
      }
    },
    list: {
      addDesignPackage: 'Voeg designpakket toe',
      generateVariationDesignPackages: 'Designpakketten voor prijsvarianten genereren',
      editContent: 'Content bewerken',
      roomFilter: 'Filter op ruimte',
      styleFilter: 'Filter op stijl',
      brandFilter: 'Filter op merk',
      title: 'Designpakketten',
      productPackageFilter: 'Filter op productpakket',
      standardFilter: 'Filter op standaard'
    }
  },
  projectDetails: {
    labels: {
      shellOnlyAllowed: 'Casco toegestaan',
      closedOn: 'Afgerond op',
      designPackageCount: 'Designpakketten',
      projectDeveloper: 'Projectontwikkelaar',
      publishedOn: 'Gepubliceerd op',
      lastImportedOn: 'Laatst geïmporteerd op',
      layouts: 'Indelingen',
      realEstateCount: 'Woningen',
      requestedQuoteCount: 'Aangevraagde offertes',
      rooms: 'Ruimtes',
      status: 'Status',
      brand: 'Merk',
      styles: 'Stijlen',
      productPackages: 'Productpakketten',
      enableCustomOffer: 'Maatwerk',
      enableCustomOfferTooltipOn:
        '​AAN - Maatwerkaanvraag is ingeschakeld. Klanten kunnen via de customer portal een maatwerkaanvraag doen.',
      enableCustomOfferTooltipOff:
        'UIT - Maatwerkaanvraag is uitgeschakeld en klanten kunnen geen maatwerkaanvraag doen.',
      splitCustomOfferByRooms: 'Maatwerkaanvraag onderverdelen in ruimtes',
      splitCustomOfferByRoomsTooltipOn:
        'AAN - Maatwerkaanvraag is onderverdeeld in ruimtes waarbij de klant een maatwerkaanvraag (of gekozen design) per ruimte kan kiezen.',
      splitCustomOfferByRoomsTooltipOff:
        'UIT - Maatwerkaanvraag is gegroepeerd waarbij één maatwerkaanvraag voor alle ruimtes geldt.',
      useReturnPriceCalculation: 'Verminder retourprijs voor berekening coordinatiekosten',
      useReturnPriceCalculationTooltipOn:
        'AAN - Bij het berekenen van de coordinatiekosten wordt de retourprijs in mindering gebracht.',
      useReturnPriceCalculationTooltipOff:
        'UIT - De coordinatiekosten worden op basis van de productprijs berekend (zonder retourprijs).',
      useProjectActivityCosts: 'Gebruik activiteitskosten voor project',
      useProjectActivityCostsTooltipOn:
        '​AAN - De installatiekosten zijn gebaseerd op de activiteiten die aan de productsets gekoppeld zijn.',
      useProjectActivityCostsTooltipOff:
        'UIT - De installatiekosten worden berekend op basis van de kosten van de productset en houden rekening met de installatiecorrecties.',
      dossierEmails: 'E-mailadressen voor dossier',
      dossierEmailsPlaceholder: 'Vul een e-mailadres in en druk op enter',
      margins: {
        companyMarginSanitary: 'Opslagpercentage sanitair project',
        companyMarginTiles: 'Opslagpercentage tegels project',
        marginInstaller: 'Marge sanitair installateur project',
        marginTiler: 'Marge tegels tegelzetter project',
        marginBuilder: 'Marge sanitair & tegels aannemer project',
        correctionInstallationWFactor: 'Extra correctie installatieprijzen W project',
        correctionInstallationEFactor: 'Extra correctie installatieprijzen E project',
        correctionInstallationCVFactor: 'Extra correctie installatieprijzen CV project',
        correctionTilerFactor: 'Extra correctie tegelzetterprijzen project',
        correctionConstructionFactor: 'Extra correctie bouwkundige prijzen project'
      }
    },
    errors: {
      percentagePositive: 'De waarde moet een getal tussen 0 en 100 zijn',
      percentageAllowNegative: 'De waarde moet een getal tussen -100 en 100 zijn',
      invalidEmail: 'Het e-mailadres is niet in het juiste formaat',
      duplicateEmail: 'Dit e-mailadres bestaat al'
    },
    title: 'Projectgegevens',
    downloadCostsButton: 'Download kostenoverzicht',
    downloadOverviewButton: 'Overzicht downloaden',
    downloadOverviewFile: 'Overzicht'
  },
  projectDetailView: {
    close: 'Project sluiten',
    enrich: 'Importeer data',
    unpublish: 'Depubliceren',
    publish: 'Publiceren',
    reopen: 'Heropenen',
    tabs: {
      designPackages: 'Designpakketten',
      project: 'Project',
      realEstates: 'Woningen',
      rooms: 'Ruimtes',
      styles: 'Stijlen',
      brands: 'Merken',
      productPackages: 'Productpakketten',
      articleReturnPrices: 'Retourprijzen'
    },
    firstTitle: 'Kies het project om informatie van te importeren',
    secondTitle: 'Kies een layout',
    paragraph: 'Alle ruimtes met deze layout worden geimporteerd en gekoppeld aan de eerder aangevinkte layout.'
  },
  projectRealEstates: {
    inviteSelectedRealEstates: 'Nodig geselecteerde woningen uit',
    preview: 'Preview',
    realEstateOrder: 'Bekijk keuzebevestiging',
    externalPreview: 'Externe preview',
    resetOrder: 'Reset opdracht',
    resetSelections: 'Reset selecties',
    resetSelectionsInfo: 'Reset de opdracht om deze optie in te schakelen.',
    enableNotifications: 'Notificaties inschakelen',
    disableNotifications: 'Notificaties uitschakelen',
    externalAction: 'Externe actie',
    addNote: 'Notitie toevoegen',
    uploadDocuments: 'Documenten uploaden',
    sendDossier: 'Dossier verzenden',
    sendNotificationSubmenu: {
      title: 'Herinnering sturen',
      sendReminder: 'Handmatige herinnering',
      sendInvitationReminder: 'Handmatige teaser'
    },
    confirmSelection: {
      title: 'Bevestig selectie',
      casco: 'Kies casco',
      standard: 'Kies standaard'
    },
    table: {
      buildNumber: 'Bouw nr.',
      deadline: 'Deadline',
      contactDetail: 'Contact',
      lastLogin: 'Ingelogd',
      hasNotifications: 'Meldingen',
      hasRequestForQuote: 'Bevestigd',
      offerNumber: 'Offerte nr.',
      layout: 'Indeling',
      invitationSent: 'Uitgenodigd',
      externalAction: 'Actie',
      dossierSent: 'Dossier',
      status: {
        label: 'Status',
        tooltips: {
          lastChanged: 'Laatst gewijzigd'
        }
      },
      customOffer: {
        label: 'Maatwerk',
        tooltips: {
          appointment: 'Afspraak',
          status: 'Status',
          flagTooltip: 'Offerteopvolging'
        }
      }
    },
    title: 'Woningen',
    labels: {
      lastNoLoginNotification: 'Geen login',
      lastNoQuoteNotification: 'Geen offerte',
      notificationsDisabledInfo: 'Notificaties zijn uitgeschakeld'
    },
    view: {
      contactName: 'Naam contact',
      hasBath: 'Met bad',
      isXL: 'XL',
      lastLogin: 'Laatste login',
      noLastLogin: 'Niet',
      noQuotesRequested: 'Nee',
      quotesRequested: 'Offerte aanvraagd',
      requestForQuotes: 'Geselecteerde designpakketten',
      title: 'Woning details',
      contactFormalName: 'Volledige naam'
    },
    confirmedStatus: {
      additional_work: 'Meerwerk',
      casco: 'Casco',
      standard: 'Standaard',
      casco_plus: 'Casco+',
      standard_plus: 'Standaard+'
    },
    addNotePopup: {
      title: 'Notitie toevoegen',
      subtitle: 'Notitie toevoegen - Bouwnummer ',
      label: 'Opmerking',
      submitButton: 'Notitie toevoegen'
    },
    uploadPopup: {
      subtitle: 'Documenten uploaden voor bouwnummer ',
      filename: 'Bestandsnaam',
      filesSubtitle: 'Documenten woning',
      noFilesText: 'De woning bevat nog geen bestanden',
      recipients: 'Ontvangers (gescheiden door ";")'
    },
    sendDossierPopup: {
      title: 'Dossier verzenden',
      subtitle: 'Dossier verzenden voor bouwnummer ',
      submitButton: 'Dossier verzenden'
    }
  },
  realEstateDetails: {
    backButtonText: 'Terug naar overzicht',
    buildNumber: 'Bouwnummer',
    status: 'Status',
    closingDate: 'Sluitingsdatum',
    contact: 'Koper',
    contactDetails: {
      email: 'Emailadres',
      legalName: 'Naam koper',
      phone: 'Telefoon',
      invitedDate: 'Uitgenodigd op',
      lastLogin: 'Laatst ingelogd'
    },
    realEstate: 'Details woning',
    realEstateDetails: {
      offerNumber: 'Offertenummer',
      layout: 'Indeling',
      rooms: 'Ruimtes',
      notificationsEnabled: 'Notificaties ingeschakeld'
    },
    notifications: {
      lastNoLoginNotification: 'Herinnering login',
      lastNoQuoteNotification: 'Herinnering keuzebevestiging'
    },
    requestForQuote: 'Keuzes koper',
    requestForQuoteDate: 'Datum keuzebevestiging',
    noChoices: 'Er is nog geen keuze bevestigd',
    selectedDesignPackages: 'Geselecteerde designpakketten',
    customOffer: 'Maatwerkaanvraag',
    customOfferDetails: {
      status: 'Status',
      detailsLink: 'Klik hier om naar de details van de maatwerkaanvraag te gaan',
      noCustomOffer: 'Er zijn momenteel geen lopende maatwerkaanvragen'
    },
    dossierSent: 'Datum dossier verstuurd',
    noDossierSent: 'Er is nog geen dossier verstuurd',
    files: 'Documenten',
    noFiles: 'Er zijn nog geen documenten geüpload',
    contactRequests: 'Contactaanvragen',
    contactRequestDetailsLink: 'Klik hier om naar de details van de contactaanvraag te gaan',
    noContactRequest: 'Er zijn nog geen contactaanvragen',
    externalQuestions: 'Externe vragen',
    noExternalQuestions: 'Er zijn nog geen externe vragen',
    externalQuestionsQuestion: 'Vraag',
    externalQuestionsAnswer: 'Antwoord',
    notes: "Notities",
    noNotes: "Er zijn nog geen notities"
  },
  projectRoom: {
    create: {
      layouts: 'Voeg ruimte toe aan indelingen',
      roomName: 'Naam ruimte',
      roomPosition: 'Positie ruimte',
      title: 'Voeg ruimtes toe voor dit project'
    },
    edit: {
      layouts: 'Ruimte hoort bij volgende indelingen',
      roomName: 'Naam ruimte',
      roomPosition: 'Positie ruimte',
      title: 'Bewerk ruimte voor dit project'
    },
    errors: {
      name: {
        alphaNumSpace: 'Naam van ruimte mag alleen alphanumerieke karakters en (daartussen) spaties bevatten.',
        isUnique: 'Naam van ruimte moet uniek zijn binnen een project.',
        maxLength: 'Naam van ruimte mag maximaal 40 karakters lang zijn',
        required: 'Naam van ruimte is een verplicht veld.'
      },
      position: {
        integer: 'Positie moet een geheel getal zijn.',
        minValue: 'Positie moet nul of groter zijn.',
        required: 'Positie is een verplicht veld.'
      },
      selectedLayouts: {
        required: 'Kies minimaal één indeling'
      }
    },
    list: {
      table: {
        designPackages: 'Designpakketten',
        layouts: 'Indelingen',
        name: 'Naam',
        position: 'Positie',
        title: 'Opgeslagen ruimtes'
      },
      title: 'Ruimtes'
    }
  },
  projectStyles: {
    list: {
      addedDesignPackage: 'designpakket toegevoegd',
      addedDesignPackages: 'designpakketten toegevoegd',
      description: 'Activeer minimaal 1 stijl die binnen dit project zal worden aangeboden.',
      title: 'Stijlen voor dit project',
      selectStyle: 'Selecteer een stijl',
      noDataText: 'Er zijn geen stijlen gevonden',
      tableHeaders: {
        name: 'Naam',
        designPackageCount: 'Designpakketten'
      }
    }
  },
  style: {
    create: {
      errors: {
        name: {
          isUnique: 'Naam moet uniek zijn.',
          maxLength: 'Naam mag maximaal 50 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      form: {
        styleName: 'Naam van stijl *'
      },
      title: 'Voeg stijl toe'
    },
    copy: {
      errors: {
        name: {
          isUnique: 'Naam moet uniek zijn.',
          maxLength: 'Naam mag maximaal 50 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      form: {
        styleName: 'Naam van stijl *',
        defaultFieldValue: '(Kopie)'
      },
      title: 'Stijl kopiëren'
    },
    list: {
      addStyle: 'Voeg stijl toe',
      editContent: 'Content bewerken',
      viewImports: 'Importeren',
      title: 'Beheer stijlen',
      copy: 'Kopiëren',
      edit: 'Bewerken'
    }
  },
  projectBrands: {
    list: {
      addedDesignPackage: 'designpakket toegevoegd',
      addedDesignPackages: 'designpakketten toegevoegd',
      description: 'Activeer het merk dat binnen dit project zal worden aangeboden.',
      title: 'Merk voor dit project',
      selectBrand: 'Selecteer een merk',
      noDataText: 'Er zijn geen merken gevonden',
      tableHeaders: {
        name: 'Naam',
        designPackageCount: 'Designpakketten'
      }
    }
  },
  brand: {
    create: {
      errors: {
        name: {
          isUnique: 'Naam moet uniek zijn.',
          maxLength: 'Naam mag maximaal 50 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      form: {
        styleName: 'Naam van merk *'
      },
      title: 'Voeg merk toe'
    },
    copy: {
      errors: {
        name: {
          isUnique: 'Naam moet uniek zijn.',
          maxLength: 'Naam mag maximaal 50 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      form: {
        brandName: 'Naam van merk *',
        defaultFieldValue: '(Kopie)'
      },
      title: 'Merk kopiëren'
    },
    list: {
      edit: 'Bewerken',
      copy: 'Kopiëren',
      viewImports: 'Importeren',
      addBrand: 'Voeg merk toe',
      editContent: 'Content bewerken',
      title: 'Beheer merken'
    }
  },
  projectArticleReturnPrices: {
    title: 'Retourprijzen',
    searchPlaceholder: 'Zoek op artikelcode of omschrijving',
    list: {
      headers: {
        code: 'Artikelcode',
        description: 'Omschrijving',
        combination: 'Combinatie',
        returnPrice: 'Retourprijs'
      },
      noDataText: 'Geen artikelprijzen gevonden',
      noResultsText: 'Geen artikelprijzen gevonden',
      emptyFieldsWarning: 'Het lijkt er op dat niet alle artikelen een retourprijs hebben.',
      returnPricePlaceholder: 'xxxx,xx'
    }
  },
  activities: {
    table: {
      headers: {
        activity: 'Activiteit',
        combination: 'Combinatie',
        CV_INSTALLATION_COSTS: 'CV-installatiekosten',
        E_INSTALLATION_COSTS: 'E-installatiekosten',
        W_INSTALLATION_COSTS: 'W-installatiekosten',
        INSTALLATION_CONSTRUCTION_COSTS: 'Bouwkundige kosten',
        TILER_INSTALLATION_COSTS: 'Tegelzetter',
        KITTER_INSTALLATION_COSTS: 'Kitter'
      },
      activityTarget: {
        PRODUCT_SET: 'Kosten per productset',
        SQUARE_METERS: 'Kosten per vierkante meter (m²)',
        METERS: 'Kosten per strekkende meter (m)'
      },
      noDataText: 'Er zijn nog geen activiteiten voor dit project',
      noResultsText: 'Er zijn geen activiteiten gevonden op basis van jouw zoekterm',
      validations: {
        money: 'De waarde moet een geldig kommagetal zijn boven de nul'
      }
    },
    import: 'Selecteer een standaard',
    importDialog: {
      title: 'Importeer een activiteitensjabloon',
      label: 'Naam van de activiteitsjabloon',
      confirm: 'Importeren'
    }
  },
  editActivityTemplate: {
    title: 'Bewerk activiteitensjabloon',
    table: {
      headers: {
        activity: 'Activiteitssjabloon'
      },
      noDataText: 'Er zijn nog geen activiteitensjablonen voor dit project',
      noResultsText: 'We konden geen activiteitssjabloon vinden op basis van je zoekterm'
    },
    errors: {
      name: {
        maxLength: 'Naam mag maximaal 150 karakters lang zijn',
        required: 'Naam is een verplicht veld.'
      }
    },
    backToOverview: 'Terug naar overzicht'
  },
  orderOverviewActivities: {
    table: {
      noDataText: 'Deze product set heeft geen toegewezen activiteiten',
      noResultsText: 'Er zijn geen activiteiten gevonden op basis van jouw zoekterm',
      total: 'Totaal'
    }
  },
  productPackage: {
    create: {
      errors: {
        name: {
          isUnique: 'Naam moet uniek zijn.',
          maxLength: 'Naam mag maximaal 50 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      form: {
        productPackageName: 'Naam van productpakket *'
      },
      title: 'Productpakket toevoegen'
    },
    copy: {
      errors: {
        name: {
          isUnique: 'Naam moet uniek zijn.',
          maxLength: 'Naam mag maximaal 50 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      form: {
        productPackageName: 'Naam van productpakket *',
        defaultFieldValue: '(Kopie)'
      },
      title: 'Productpakket kopiëren'
    },
    imports: {
      title: 'Importeren productpakketten',
      addImport: 'Productpakket importeren',
      updateImport: 'Update productpakket',
      getTemplate: 'Download template',
      getTemplateFilename: 'Sjabloonbestand voor productpakket importeren',
      validations: {
        maxSize: 'Dit veld mag maximaal 50 karakters lang zijn'
      },
      form: {
        productPackageName: 'Naam productpakket'
      },
      list: {
        headers: {
          productPackageName: 'Productpakket',
          fileName: 'Bestandsnaam',
          uploadDate: 'Toegevoegd op',
          path: 'Pad',
          status: 'Status',
          finishDate: 'Voltooid op',
          information: 'Informatie'
        }
      }
    },
    list: {
      viewProductPackageImports: 'Importeren',
      addProductPackage: 'Voeg productpakket toe',
      editContent: 'Content bewerken',
      edit: 'Bewerken',
      downloadImportFile: 'Download importbestand',
      copy: 'Kopiëren',
      title: 'Beheer productpakketten',
      isMainTooltip:
        'Dit is een hoofdproductpakket. Dit betekent dat het niet verwijderd kan worden. Daarnaast kunnen alleen product sets van hoofdproductpakketten gebruikt worden voor het genereren van QR codes.'
    },
    edit: {
      title: 'Beheer productpakket',
      fields: {
        name: {
          label: 'Naam',
          placeholder: 'Naam productpakket'
        }
      },
      category: {
        brandLine: {
          allowBrands: 'Onderverdelen in categorieen',
          name: 'Naam categorie',
          add: 'Voeg categorie toe'
        },
        productSets: {
          add: 'Voeg productset toe',
          copy: {
            errors: {
              name: {
                maxLength: 'Naam mag maximaal 150 karakters lang zijn.',
                required: 'Naam is een verplicht veld.'
              }
            },
            form: {
              productSetName: 'Naam van productset *',
              defaultFieldValue: '(Kopie)'
            },
            title: 'Productset toevoegen'
          },
          upsert: {
            title: 'Productset toevoegen/updaten',
            subtitle: 'Productset toevoegen/updaten uit productsetgroep ',
            fields: {
              brandline: {
                placeholder: 'Categorie'
              },
              installationCosts: {
                ifCombinationIsStandard: 'Als combinatie standaard is',
                ifCombinationIsNotStandard: 'Als combinatie niet standaard',
                CV_INSTALLATION_COSTS: {
                  label: 'CV-installatiekosten'
                },
                E_INSTALLATION_COSTS: {
                  label: 'E-installatiekosten'
                },
                W_INSTALLATION_COSTS: {
                  label: 'W-installatiekosten '
                },
                INSTALLATION_CONSTRUCTION_COSTS: {
                  label: 'Bouwkundige kosten'
                },
                TILER_INSTALLATION_COSTS: {
                  label: 'Kosten tegelzetter'
                }
              },
              installationCostsTiles: {
                ifPlacedOnFloor: 'Als tegel geplaatst is op de vloer',
                ifPlacedOnWall: 'Als tegel geplaatst is op de muur'
              },
              isDefault: {
                label: 'Standaard'
              },
              name: {
                placeholder: 'Naam'
              },
              productCode: {
                label: 'Artikelnummer',
                noDataText: 'Geen producten gevonden'
              },
              withoutProducts: {
                label: 'Zonder producten'
              },
              activities: {
                label: 'Naam activiteit',
                noDataText: 'Geen activiteiten gevonden'
              },
              priceVariants: {
                label: 'Markeer als standaard voor prijsvariant',
                categoryLabel: 'Productset groep'
              }
            },
            productListTable: {
              code: 'Code',
              name: 'Naam',
              primary: 'Primair',
              tilesCostPrice: 'Prijs',
              tilesLinearPrice: 'm1 prijs',
              packagingUnits: 'VE'
            },
            priceVariantsListTable: {
              priceVariant: 'Prijsvariant',
              categoryName: 'Productset groep'
            },
            priceTypes: {
              METERS: 'M1',
              SQUARE_METERS: 'M2',
              PRODUCT_SET: 'Product'
            },
            sections: {
              costs: 'Kosten',
              products: 'Producten',
              properties: 'Eigenschappen',
              customProperties: 'Optionele eigenschappen',
              brandline: 'Categorie',
              priceVariants: 'Standaard voor prijsvarianten'
            },
            addProductBtn: 'Nieuw product toevoegen',
            addPriceVariantBtn: 'Toevoegen voor prijsvariant',
            validations: {
              money: 'De waarde moet een geldig kommagetal zijn boven de nul',
              numeric: 'De waarde moet een heel getal zijn groter of gelijk aan nul',
              decimal: 'De waarde moet een decimaal zijn groter of gelijk aan nul',
              decimalPlaces2: 'moet een positief getal zijn met maximaal 2 decimale posities',
              percentage: 'Moet een percentage tussen de 0% en 100% zijn',
              required: 'Dit veld is verplicht',
              maxsize: 'Dit veld mag maximaal 150 karakters lang zijn',
              color: 'Dit veld moet een geldige kleurcode zijn',
              maxValue_1000: 'Moet een decimaal getal zijn tussen 0 en 1000',
              maxValue_100000: 'Moet een decimaal getal zijn tussen 0 en 100000',
              maxValue_1000000: 'Moet een decimaal getal zijn tussen 0 en 1000000'
            }
          },
          list: {
            noDataText: 'Er zijn geen product sets gevonden',
            table: {
              category: 'Categorie',
              name: 'Naam productset',
              price: 'Prijs',
              priceVariant: 'Prijsvariant',
              available: 'Beschikbaar',
              disabled: 'Niet beschikbaar',
              disabledQrExplanation:
                'Dit is geen hoofdproductpakket. Je kan alleen QR codes genereren van productsets uit hoofdproductpakketten.'
            }
          },
          addProductModal: {
            title: 'Nieuw type tegel toevoegen',
            fields: {
              code: 'Artikelnummer',
              name: 'Naam tegel',
              price: 'Prijs',
              supplierCode: 'Artikelnummer leverancier',
              packagingUnits: 'Verpakkingseenheid',
              priceType: 'Prijs per'
            },
            validations: {
              maxSize: 'Dit veld mag maximaal 150 karakters lang zijn',
              money: 'De waarde moet een geldig kommagetal zijn boven de nul',
              required: 'Dit veld is verplicht'
            }
          },
          bulkEdit: {
            bulkEditButton: 'Update selectie',
            modalTitle: 'Update geselecteerde productsets',
            modalSubtitle: 'Update de prijzen van de geselecteerde productsets',
            modalExplanation:
              '* Alleen ingevulde velden worden geupdate. Bij niet gevulde velden blijft de waarde gelijk.',
            ifCombinationIsStandard: 'Als combinatie standaard is',
            ifCombinationIsNotStandard: 'Als combinatie niet standaard'
          }
        }
      },
      dialogs: {
        brandLine: {
          delete: {
            title: 'Categorie verwijderen',
            text: 'Weet je zeker dat je deze categorie wilt verwijderen?'
          }
        },
        productSet: {
          delete: {
            title: 'Productset verwijderen',
            text: 'Weet je zeker dat je deze productset wilt verwijderen?'
          }
        }
      },
      errors: {
        brandLine: {
          isUnique: 'Naam moet uniek zijn binnen deze categorie.',
          maxLength: 'Naam mag maximaal 100 karakters lang zijn.',
          required: 'Naam is een verplicht veld.'
        }
      },
      backToOverview: 'Terug naar overzicht'
    }
  },

  activityTemplate: {
    title: 'Activiteitensjablonen',
    addActivityTemplate: 'Activiteitssjabloon toevoegen',
    addImport: 'Activiteitensjablonen importeren',
    importActivityTemplateDialog: {
      placeholder: 'Naam van de activiteitssjabloon *',
      validations: {
        maxSize: 'Dit veld mag maximaal 150 karakters lang zijn'
      }
    },
    getTemplate: 'Download template',
    getTemplateFilename: 'Sjabloonbestand voor activiteitensjablonen importeren',
    addActivityDialog: {
      title: 'Activiteitssjabloon toevoegen',
      placeholder: 'Naam van de activiteitssjabloon',
      validations: {
        maxSize: 'Dit veld mag maximaal 150 karakters lang zijn'
      }
    },
    list: {
      edit: 'Edit'
    }
  },
  projectProductPackage: {
    list: {
      title: 'Productpakketten voor dit project',
      description: 'Voor een project is minimaal een productpakket vereist. Kies het hier.',
      addedDesignPackage: 'designpakket toegevoegd',
      addedDesignPackages: 'designpakketten toegevoegd',
      notEditable:
        'Het geselecteerde productpakket kan niet worden gewijzigd als er nog enkele ontwerppakketten zijn met een bestaand productpakket.'
    }
  },
  drawings: {
    title: '3D tekeningen',
    list: {
      noDataText: 'Er zijn (nog) geen aanvragen voor 3D tekeningen',
      noResultsText: 'Er zijn geen aanvragen gevonden op basis van uw zoek criteria',
      searchPlaceholder: 'Projectnaam, bouwnummer of contactpersoon',
      statusFilterPlaceholder: 'Filter op status',
      drawerFilterPlaceholder: 'Filter op tekenaar',
      hideCanceledCompletedLabel: 'Verberg geannuleerderde/afgeronde tekeningen',
      uploadDrawing: 'Upload 3D tekening',
      assignDrawer: 'Tekenaar toewijzen',
      downloadDrawing: 'Download 3D tekening',
      headers: {
        requested: 'Aangevraagd',
        project: 'Project',
        buildNr: 'Bouwnr.',
        room: 'Ruimte',
        designName: 'Design',
        contact: 'Contact',
        email: 'E-mail',
        assignedDrawerName: 'Tekenaar',
        deadline: 'Deadline',
        status: 'Status'
      }
    },
    status: {
      REQUESTED: 'Nieuw',
      READY: 'Voltooid',
      CANCELLED: 'Geannuleerd',
      REQUESTED_AND_CANCELABLE: 'Aangevraagd en binnen bedenktijd',
      PENDING: 'In behandeling',
      IN_PROGRESS: 'In behandeling'
    },
    uploadPopup: {
      subtitle: 'Upload 3D tekening voor',
      inputPlaceholder: 'Selecteer bestand'
    }
  },
  technicalDrawings: {
    title: 'Technische tekeningen',
    list: {
      noDataText: 'Er zijn geen verzoeken voor technische tekeningen  ',
      noResultsText: 'Er zijn geen aanvragen gevonden op basis van uw zoek criteria',
      searchPlaceholder: 'Projectnaam, bouwnummer of contactpersoon',
      statusFilterPlaceholder: 'Filter op status',
      drawerFilterPlaceholder: 'Filter op tekenaar',
      hideCanceledCompletedLabel: 'Verberg geannuleerderde/afgeronde tekeningen',
      uploadDrawing: 'Upload technische tekening',
      downloadDrawing: 'Technische tekening downloaden',
      assignDrawer: 'Tekenaar toewijzen',
      headers: {
        requested: 'Aangevraagd op',
        project: 'Project',
        buildNr: 'Bouwnr.',
        room: 'Ruimtes',
        designName: 'Naam design',
        contact: 'Contact',
        drawer: 'Tekenaar',
        email: 'Email',
        deadline: 'Deadline',
        status: 'Status'
      }
    },
    status: {
      REQUESTED: 'Nieuw',
      READY: 'Voltooid',
      CANCELLED: 'Geannuleerd',
      REQUESTED_AND_CANCELABLE: 'Nieuw en maatwerkaanvraag',
      PENDING: 'In behandeling',
      IN_PROGRESS: 'In behandeling'
    },
    uploadPopup: {
      subtitle: 'Upload technische tekening voor',
      inputPlaceholder: 'Selecteer bestand',
      project: 'project',
      buildNr: 'met bouwnummer'
    },
    assignDrawerPopUp: {
      subtitle: 'Wijs een tekenaar toe voor',
      inputPlaceHolder: 'Tekenaar toewijzen'
    },
    reject: 'Afwijzen'
  },
  assignDrawer: {
    title: 'Tekenaar toewijzen',
    subtitle: 'Wijs een tekenaar toe door een tekenaar uit de lijst te kiezen en op opslaan te klikken.',
    selectDrawerPlaceholder: 'Selecteer een tekenaar'
  },
  drawingRequestDetails: {
    title: 'Details 3D tekening',
    designNamePrefix: 'Alle details voor tekening',
    buildNumber: 'Bouwnummer',
    roomName: 'Ruimte',
    drawingRequestedOn: 'Tekening aangevraagd op',
    drawingStatus: 'Status',
    projectName: 'Project'
  },
  customOffers: {
    title: 'Aanvragen voor maatwerk',
    list: {
      noDataText: 'Er zijn geen maatwerkaanvragen',
      noResultsText: 'We konden geen maatwerkaanvragen vinden op basis van jouw zoekcriteria',
      searchPlaceholder: 'Bouwnummer of emailadres',
      filterStatusPlaceholder: 'Filter op status',
      filterSpecialistPlaceholder: 'Filter op showroom specialist',
      newOfferRequestButton: 'Nieuwe aanvraag',
      hideCanceledCompleted: 'Verberg geannuleerderde/afgeronde aanvragen',
      headers: {
        appointmentType: 'Type',
        requestedOn: 'Aangevraagd op',
        project: 'Project',
        buildNr: 'Bouwnummer',
        rooms: 'Ruimtes',
        email: 'Email',
        specialist: 'Specialist',
        closingDate: 'Sluitingsdatum',
        status: 'Status'
      }
    },
    newRequestDialog: {
      title: 'Maatwerk aanvraag genereren',
      appointmentType: 'Type afspraak',
      project: 'Project',
      buildNumber: 'Bouwnummer',
      requestNotes: 'Notities'
    }
  },
  customOfferDetails: {
    title: 'Maatwerk - Bouwnummer ',
    closingDate: 'Sluitingsdatum',
    backButtonText: 'Terug naar overzicht',
    cancelButtonText: 'Verzoek annuleren',
    cancelChangeRequestButtonText: 'Annuleer wijzigingsverzoek ',
    completeOfferButtonText: 'Maatwerkaanvraag voltooien',
    tooltipText: 'Je kunt een wijzigingsverzoek alleen annuleren als de maatwerkaanvraag is gedeeld met de klant',
    reopenButtonText: 'Heropenen',
    appointmentType: 'Type afspraak',
    assignedSpecialist: 'Showroomspecialist',
    scheduledDates: 'Afspraken ingepland',
    noAppointments: 'Er zijn nog geen afspraken',
    dateAppointmentButton: '+ Afspraak',
    contact: 'Contactgegevens',
    contactDetails: {
      name: 'Naam',
      email: 'e-mailadres',
      phone: 'Telefoon'
    },
    requestDetailsLabel: 'Details aanvraag',
    requestDetails: {
      requestedDate: 'Datum van aanvraag',
      contactPreference: 'Contactvoorkeur',
      rooms: 'Ruimtes'
    },
    notes: 'Opmerkingen aanvragen',
    offersList: {
      title: 'Offertes',
      addButton: 'Voeg document toe',
      columns: {
        name: 'Naam',
        rooms: 'Ruimtes',
        created: 'Aangemaakt op',
        sharedWithCustomer: 'Gedeeld met klant',
        downloadFiles: 'Bestanden downloaden'
      }
    },
    upsertOfferPopup: {
      titleAdd: 'Offerte toevoegen',
      titleEdit: 'Offerte bewerken',
      subtitle: 'Voeg een offerte voor alle ruimtes toe',
      name: 'Offertenaam',
      link: 'Link naar 360 visual',
      uploadDrawingBtn: 'Upload tekening',
      uploadOfferBtn: 'Upload maatwerk',
      shareWith: 'Delen met klant',
      costSpecification: {
        title: 'Kostenspecificatie',
        globalOfferColumnName: 'Volgens offerte',
        productsAndMaterials: 'Producten en materialen',
        tilesAndMaterials: 'Tegels en materialen',
        lowerPriceStandardProducts: 'Retour standaard',
        services: 'Diensten debadkamer.nl',
        productsAndServicesSubtotal: 'Subtotaal producten en diensten',
        coordinationComissionBuilder: 'Opslag coördinatie & risico aannemer sanitair',
        coordinationComissionInstaller: 'Opslag coördinatie & risico installateur sanitair',
        coordinationProvisionSubtotal: 'Subtotaal coordinatie provisie',
        installationConstruction: 'Meerprijs bouwkundig ten behoeve van de sanitaire installatie',
        kitter: 'Kitter',
        tiler: 'Tegelzetter',
        wInstallation: 'Meerprijs installatie gekozen producten sanitair',
        eInstallation: 'Meerprijs arbeid electra voorzieningen',
        cvInstallation: 'Meerprijs arbeid ten behoeve van de radiator',
        installationAndConstructionsubtotal: 'Subtotaal installatie en bouwkundig',
        totalExclVat: 'Totaal excl. B.T.W.',
        vat: 'B.T.W. 21%',
        totalInclVat: 'Totaal incl. B.T.W.',
        total: 'Totaal',
        validations: {
          required: 'Dit veld is verplicht',
          money: 'De waarde moet een geldig kommagetal zijn boven de nul',
          negativeMoney: 'Deze waarde moet negatief of gelijk aan nul zijn'
        }
      }
    },
    addAppointmentModal: {
      title: 'Nieuwe afspraak toevoegen',
      invalidTimeFormat: 'Geldig tijdformaat is hh:mm',
      invalidDateFormat: 'Geldig datumformaat is yyyy-mm-dd'
    },
    editAppointmentModal: {
      title: 'Afspraak bewerken'
    }
  },
  customerOrder: {
    customOfferLabel: 'Maatwerk offerte',
    name: 'naam',
    createdOn: 'Aangemaakt op',
    buildNumber: 'Bouwnummer',
    layout: 'Indeling',
    contact: 'Contactgegevens',
    lastLogin: 'Laatst ingelogd',
    requestDate: 'Datum bevestiging',
    costOverview: 'Kostenoverzicht',
    costItems: {
      priceProductsAndMaterials: 'Totaal Producten en materialen',
      priceTilesAndFasteners: 'Totaal Tegels en bevestigingsmaterialen',
      priceServices: 'Totaal Diensten volgens offerte',
      priceReturnPrices: 'Totaal retour standaard ',
      subTotal_productsAndMaterials_services_returnPrices: 'Subtotaal producten en services',
      priceCoordinationProvisionInstaller: 'Opslag coördinatie & risico installateur sanitair',
      priceCoordinationProvisionContractor: 'Opslag coördinatie & risico aannemer sanitair',
      subTotal_coordinationProvisionInstaller_coordinationProvisionContractor: 'Subtotaal Coordinatie provisie',
      priceConstructionCosts: 'Meerprijs arbeid bouwkundig ten behoeve van de sanitaire installatie',
      priceWInstallation: 'Meerprijs arbeid gekozen producten sanitair',
      priceEInstallation: 'Meerprijs arbeid electra voorzieningen',
      priceCVInstallation: 'Meerprijs arbeid ten behoeve van de radiator',
      subTotal_priceConstructionCosts_priceWInstallation_priceEInstallation_priceCVInstallation_priceTiler_priceKitter:
        'Subtotaal installatie en bouwkundig',
      totalExVat: 'Totaal excl. B.T.W.',
      priceVat: 'B.T.W. 21%',
      totalIncVat: 'Totaal incl. B.T.W.',
      subTotal_productsAndMaterials_returnPrices: 'Subtotaal producten',
      priceTiler: 'Tegelzetter',
      priceKitter: 'Kitter'
    },
    productChoices: 'Productkeuzes',
    casco: 'Er is voor casco gekozen',
    downloadOffer: 'Download offerte',
    downloadDrawing: 'Download tekening'
  },
  components: {
    productSetCard: {
      articleCodeLabel: 'Artikel code',
      noArticlesLabel: 'Deze productset heeft (nog) geen producten'
    },
    returnedProductSet: {
      title: '* Producten retour',
      articleCodeLabel: 'Artikel code',
      noReturnedArticles: 'Geen geretourneerde artikelen'
    },
    priceTypes: {
      METERS: 'Strekkende meters',
      SQUARE_METERS: 'Vierkante meters',
      PRODUCT_SET: 'Stuks'
    }
  },
  application: {
    categories: {
      title: 'Beheer productset groepen',
      list: {
        editContent: 'Content bewerken',
        edit: 'Wijzigen',
        add: 'Voeg productset groep toe',
        upsert: {
          edit: 'Productset groep aanpassen',
          add: 'Voeg productset groep toe',
          errors: {
            name: {
              isUnique: 'De naam moet uniek zijn voor elke combinatie.',
              required: 'Naam van ruimte is een verplicht veld.'
            },
            position: {
              required: 'Positie is een verplicht veld.'
            }
          },
          infoKeys: {
            title: 'Productset eigenschappen',
            addBtn: 'Eigenschap toevoegen',
            list: {
              headers: {
                name: 'Naam',
                position: 'Positie',
                displayable: 'Zichtbaar in details',
                filterable: 'Filterbaar'
              }
            },
            add: {
              title: 'Eigenschap toevoegen',
              labelNL: 'Naam NL',
              labelNLPlaceholder: 'Nederlandse naam van eigenschap',
              labelEN: 'Naam EN',
              labelENPlaceholder: 'Engelse naam van eigenschap',
              errors: {
                label: {
                  isUnique: 'Dit veld moet uniek zijn',
                  required: 'Dit veld is verplicht'
                }
              }
            }
          }
        },
        headers: {
          name: 'Naam',
          title: 'Titel',
          translatedTitle: 'Vertaalde titel',
          position: 'Positie',
          brandlineHeader: 'Categorie header',
          translatedBrandlineHeader: 'Vertaalde categorie header',
          productCombination: 'Productcombinatie',
          translatedName: 'Vertaalde naam'
        }
      }
    }
  },
  contactRequests: {
    title: 'Contactaanvraag',
    list: {
      noDataText: 'Er zijn geen contactaanvragen',
      noResultsText: 'We konden geen contactaanvragen vinden op basis van je zoekcriteria',
      searchPlaceholder: 'Email',
      filterPlaceholder: 'Filter op status contactaanvraag',
      filterProjectPlaceholder: 'Filter op project',
      filterBuildNumberPlaceholder: 'Filter op bouwnummer',
      filterBuildNumberTooltip: 'Selecteer eerst een project',
      hideCompletedLabel: 'Verberg afgeronde aanvragen',
      openRequest: 'Open aanvraag',
      headers: {
        requester: 'Van',
        project: 'Project',
        buildNr: 'Bouwnr',
        status: 'Status',
        requestDate: 'Aangevraagd op',
        modifiedDate: 'Aangepast op'
      }
    },
    status: {
      new: 'Nieuw',
      pending: 'In behandeling',
      closed: 'Voltooid'
    },
    details: {
      title: 'Contactaanvraag - Bouwnummer ',
      backButton: 'Terug naar overzicht',
      completeRequest: 'Aanvraag voltooien',
      requestDetails: 'Details aanvraag',
      modifiedDate: 'Datum laatste aanpassing',
      requestDate: 'Datum van aanvraag',
      requester: 'Aangevraagd door',
      questionLabel: 'Vraag',
      answerLabel: 'Antwoord',
      closeContactRequestTitle: 'Contactaanvraag voltooien',
      closeContactRequestSubtitle: 'Contactaanvraag voltooien - Bouwnummer ',
      project: 'Project',
      buildNumber: 'Bouwnr',
      status: 'Status',
      phoneNumber: 'Telefoonnummer',
      contactPreference: 'Contactvoorkeur',
      contactPreferenceTypes: {
        EMAIL: 'Email',
        NO_PREFERENCE: 'Geen voorkeur',
        PHONE: 'Telefoon'
      }
    }
  },
  externalActionDialog: {
    title: 'Externe actie/vraag voor bouwnummer',
    newActionSubtitle:
      'Maak een vraag of externe actie aan die voltooid moet worden vóór het project afgerond kan worden',
    pendingActionSubtitle: 'Je kan de vraag/actie hier updaten of voltooien',
    target: 'Wie moet deze actie/vraag voltooien of beantwoorden?',
    questionLabel: 'Vraag/actie',
    answerLabel: 'Antwoord/notitie',
    createAction: 'Vraag/actie aanmaken',
    updateAction: 'Vraag/actie updaten',
    createAndResolveAction: 'Vraag/actie voltooien',
    resolveAction: 'Vraag/actie voltooien'
  },
  externalActionResolveDialog: {
    title: 'Externe actie/vraag voltooien',
    subtitle: 'Geef antwoord op de vraag of maak een notitie van de voltooide actie.',
    back: 'Terug',
    confirm: 'Bevestigen'
  },
  customOfferFollowUpDialog: {
    title: 'Offerteopvolging',
    subtitle: ' Je kan hier opschrijven wat je hebt besproken met de klant',
    noteLabel: 'Actie / Opmerking',
    cancel: 'Annuleren',
    resolve: 'Voltooien'
  },
  requestForChangeDialog: {
    title: 'Wijzigingsverzoek forceren',
    subtitle:
      'Let op: Als je een wijzigingsverzoek forceert betekent dit dat de status van de maatwerk offerte terug veranderd naar wijzigingsverzoek. Op deze manier blijft er inzichtelijk dat er nog een wijziging op de offerte nodig is. Vermeld hieronder welke wijzigingen er met de klant zijn besproken:',
    notesLabel: 'Opmerkingen',
    cancel: 'Annuleren',
    submitChanges: 'Wijzigingen indienen'
  }
}

export default dutch
