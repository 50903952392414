
import {
  AddDefaultProductSetDtoInput,
  AddPreselectedProductSetDtoInput,
  Badkamercore_ProjectType,
  Category,
  CostType,
  Currency,
  DesignPackageCost,
  DesignPackageDefaultProductSet,
  DesignPackagePreselectedProductSet,
  DesignPackageProductSetRestriction,
  DesignPackageProductSetRestrictionInfoKey,
  DesignPackageWall,
  DesignPackageWallDtoInput,
  IsTilesToCeiling,
  PriceVariant,
  ProductCombinationType,
  ProductSetInfoCompareOperation,
  ProductSetInfoKeyDataType,
  ProductSetInfoKeyType,
  ProjectStatus,
  Room,
  TupleOfGuidAndStringInput
} from '@/__generated__/globalTypes'
import QUERIES from '@/queries/queries'
import {
  alphaNumSpace,
  areaMeasureIf,
  isOptionalButMandatoryCategory,
  lengthMeasureIf,
  money,
  moneyIf,
  selectedCombination
} from '@/utils/validationUtils'
import {
  ProductPackageCombinationsDocument,
  ProductPackageCombinationsQuery,
  ProductPackageCombinationsQueryVariables
} from '@/views/productPackageView/gql/__generated__/getProductPackageCombinations.query'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { integer, minValue, required, requiredIf } from 'vuelidate/lib/validators'
import ProductCombinationConfiguration from './ProductCombinationConfiguration.vue'
import ProductCombinations from './ProductCombinations.vue'
import {
  CreateDesignPackageDocument,
  CreateDesignPackageMutation,
  CreateDesignPackageMutationVariables
} from './gql/__generated__/createDesignPackage.mutation'
import {
  GetProductSetsDefaultsDocument,
  GetProductSetsDefaultsQuery,
  GetProductSetsDefaultsQueryVariables
} from './gql/__generated__/getDefaultProductSets.query'
import {
  GetDesignPackageDocument,
  GetDesignPackageQuery,
  GetDesignPackageQueryVariables
} from './gql/__generated__/getDesignPackage.query'
import {
  GetDesignPackageRestrictionKeysDocument,
  GetDesignPackageRestrictionKeysQuery,
  GetDesignPackageRestrictionKeysQueryVariables
} from './gql/__generated__/getDesignPackageProductSetRestrictionKeys.query'
import {
  GetProjectOptionsForDesignPackageDocument,
  GetProjectOptionsForDesignPackageQuery,
  GetProjectOptionsForDesignPackageQueryVariables
} from './gql/__generated__/getProjectOptionsForDesignPackage.query'
import {
  UpdateDesignPackageDocument,
  UpdateDesignPackageMutation,
  UpdateDesignPackageMutationVariables
} from './gql/__generated__/updateDesignPackage.mutation'
import { FieldInformation, GuidEmpty, RequiredDataTypes, SelectedCombination } from './types'
import {
  GetProductSetsPreselectedQuery,
  GetProductSetsPreselectedQueryVariables,
  GetProductSetsPreselectedDocument
} from './gql/__generated__/getPreselectedProductSets.query'
import { GetLocalizedContent } from '@/utils/translationUtils'
import {
  CategoryFieldsFragment,
  CategoriesQueryQuery,
  CategoriesQueryQueryVariables,
  CategoriesQueryDocument
} from '@/views/categoriesView/gql/__generated__/CategoryCMS.query'

@Component({
  components: {
    ProductCombinations,
    ProductCombinationConfiguration
  },
  mixins: [validationMixin]
})
export default class DesignPackageComponent extends Vue {
  //#region props
  @Prop({ default: false }) isReadOnly!: boolean
  //#endregion props

  @Ref() designPackageForm?: HTMLFormElement

  //#region data
  fetchedData: GetProjectOptionsForDesignPackageQuery | null = null
  selectedRoom = ''
  selectedStyle = ''
  selectedBrand = ''
  selectedPriceVariant?: PriceVariant
  totalPrice = ''
  selectedProductPackage = ''
  name = ''
  position = 10
  hasBath = false
  hasToiletLabel = false
  isXL = false
  isStandard = true
  isTilesToCeiling = IsTilesToCeiling.NoPreselection

  productsAndInstallationMaterialsPrice = ''
  coordinationCommissionInstallerPrice = ''
  coordinationCommissionBuilderPrice = ''
  installationConstructionPrice = ''
  tilesAndFastenersPrice = ''
  wInstallationPrice = ''
  eInstallationPrice = ''
  cvInstallationPrice = ''

  combinations: SelectedCombination[] = []

  assignedDefaultProducts: DesignPackageDefaultProductSet[] = []
  assignedPreselectedProducts: DesignPackagePreselectedProductSet[] = []
  assignedRestrictions: DesignPackageProductSetRestriction[] = []
  assignedWalls: DesignPackageWallDtoInput[] = []
  categoriesCMSContent: CategoryFieldsFragment[] = []

  disabledSave = false
  isEditable = false
  isSaving = false
  setAsProjectDefault = false
  //#endregion

  //#region lifecycle
  created() {
    this.fetchDesignPackageItems()
    this.fetchDesignPackageValues()
    this.fetchProductCombinations()
    this.fetchCategoryCMSContent()
  }
  //#endregion lifecycle

  //#region getters
  get language() {
    return this.$i18n.locale
  }

  get projectId() {
    return this.$route.params.projectId
  }

  get designPackageId() {
    return this.$route.params.designPackageId
  }

  get labels() {
    return {
      title: this.designPackageId
        ? this.$t('projectDesignPackage.edit.title')
        : this.$t('projectDesignPackage.create.title'),

      room: this.designPackageId
        ? `${this.$t('projectDesignPackage.edit.roomSelect')} (${this.$t('global.readonly')})`
        : this.$t('projectDesignPackage.create.roomSelect'),

      style: this.designPackageId
        ? `${this.$t('projectDesignPackage.edit.styleSelect')} (${this.$t('global.readonly')})`
        : this.$t('projectDesignPackage.create.styleSelect'),

      brand: this.designPackageId
        ? `${this.$t('projectDesignPackage.edit.brandSelect')} (${this.$t('global.readonly')})`
        : this.$t('projectDesignPackage.create.brandSelect'),

      productPackage: this.designPackageId
        ? `${this.$t('projectDesignPackage.edit.productPackageSelect')} (${this.$t('global.readonly')})`
        : this.$t('projectDesignPackage.create.productPackageSelect'),

      //add the price variant only if not standard and read-only
      name: this.designPackageId
        ? this.$t('projectDesignPackage.edit.nameSelect') + ' (' + this.$t('global.readonly') + ')'
        : this.$t('projectDesignPackage.create.nameSelect')
    }
  }

  get isCreateNew() {
    return !this.designPackageId
  }

  get showLoader() {
    return this.$apollo.loading || this.isSaving
  }

  get priceVariants() {
    return [
      { text: 'Comfort', value: PriceVariant.Comfort },
      { text: 'Premium', value: PriceVariant.Premium },
      { text: 'Excellent', value: PriceVariant.Excellent }
    ]
  }

  get isPriceVariant() {
    return !this.isProductPackagesProject && !this.isStandard
  }

  get project() {
    return this.fetchedData?.project
  }

  get projectType() {
    return this.project?.projectType
  }

  get rooms() {
    return this.project?.rooms
  }

  get styles() {
    return this.project?.assignedStyles.flatMap((x) => x.style)
  }

  get brands() {
    return this.project?.assignedBrands.flatMap((x) => x.brand)
  }

  get productPackages() {
    return this.project?.assignedProductPackages.flatMap((x) => x.productPackage)
  }

  get isStylesProject() {
    return this.projectType === Badkamercore_ProjectType.DeBadkamerwerelden
  }

  get isBrandsProject() {
    return this.projectType === Badkamercore_ProjectType.Merkenpropositie
  }

  get isProductPackagesProject() {
    return this.projectType === Badkamercore_ProjectType.Productenpropositie
  }

  get isEditMode(): boolean {
    return this.designPackageId != undefined
  }

  get isValidAndHasChanges() {
    return !this.disabledSave && (!this.designPackageId || (this.$v.$anyDirty && !this.$v.$anyError))
  }

  get summedTotalPrice() {
    return (
      Number(this.productsAndInstallationMaterialsPrice?.replace(',', '.')) +
      Number(this.coordinationCommissionInstallerPrice?.replace(',', '.')) +
      Number(this.coordinationCommissionBuilderPrice?.replace(',', '.')) +
      Number(this.installationConstructionPrice?.replace(',', '.')) +
      Number(this.tilesAndFastenersPrice?.replace(',', '.')) +
      Number(this.wInstallationPrice?.replace(',', '.')) +
      Number(this.eInstallationPrice?.replace(',', '.')) +
      Number(this.cvInstallationPrice?.replace(',', '.'))
    )
  }

  get selectedCombinations() {
    return this.combinations.filter((x) => x.isSelected && x.combinationInfo)
  }

  get designPackageRestrictionValues() {
    let restrictionValues: TupleOfGuidAndStringInput[] = []
    this.combinations.forEach((element) => {
      if (element.isSelected) {
        restrictionValues = restrictionValues.concat(
          element.values.map(
            (x) =>
              ({
                item1: x.item1,
                item2: x.item2?.toString() ?? ''
              } as TupleOfGuidAndStringInput)
          )
        )
      }
    })
    return restrictionValues
  }

  get designPackageDefaults() {
    return this.combinations.reduce((list: AddDefaultProductSetDtoInput[], combination) => {
      if (combination.isSelected && this.isBasicInRoom(combination)) {
        const combinationDefaults =
          combination.categoriesWithAssignedProductSets
            ?.filter((cat) => !!cat.selectedDefaultProductSetsForCategory?.productSetIds?.length)
            ?.flatMap((cat): AddDefaultProductSetDtoInput[] =>
              cat.selectedDefaultProductSetsForCategory.productSetIds
                .filter((psId) => psId != GuidEmpty)
                .map((productSetId: string) => ({
                  productCategoryId: cat.selectedDefaultProductSetsForCategory.productCategoryId,
                  setAsProjectDefault: this.setAsProjectDefault,
                  productSetId: productSetId,
                  wallId: cat.selectedDefaultProductSetsForCategory.wallId
                }))
            ) ?? []

        return [...list, ...combinationDefaults]
      }
      return list
    }, [])
  }

  get designPackagePreselected() {
    return this.combinations.reduce((list: AddPreselectedProductSetDtoInput[], combination) => {
      if (combination.isSelected) {
        const combinationDefaults = combination.categoriesWithAssignedProductSets
          ?.filter((cat) => !!cat.selectedPreselectedProductSetsForCategory?.productSetIds?.length)
          ?.flatMap(
            (cat): AddPreselectedProductSetDtoInput => ({
              productCategoryId: cat.selectedPreselectedProductSetsForCategory?.productCategoryId,
              productSetId: cat.selectedPreselectedProductSetsForCategory?.productSetIds
            })
          )

        return [...list, ...combinationDefaults]
      }
      return list
    }, [])
  }

  get categoriesPages(): Category[] {
    return this.categoriesCMSContent.map((cmsCat) => GetLocalizedContent(cmsCat))
  }
  //#endregion getters

  //#region watchers

  @Watch('selectedProductPackage')
  onSelectedProductPackageChange() {
    if (!this.designPackageId) {
      this.combinations.forEach((combination) => {
        combination.isSelected = false
      })
    }
  }

  @Watch('designPackageDefaults', { immediate: true })
  onIsBasicInRoomUnselection() {
    if (this.selectedCombinations.length && !this.designPackageDefaults.length) this.setAsProjectDefault = false
  }

  @Watch('summedTotalPrice', { immediate: true })
  onSummedTotalPriceChanged() {
    this.setPrices()
  }

  @Watch('selectedRoom', { immediate: true })
  onSelectedRoomChanged() {
    if (this.isEditMode) return

    const calculatedPosition = this.rooms
      ?.find((x) => x.id == this.selectedRoom)
      ?.designPackages.map((y) => y.position)
      .sort((a, b) => b - a)[0]

    if (calculatedPosition) {
      this.position = calculatedPosition + 1
    }
  }
  //#endregion watchers

  //#region validations & errors
  get roomErrors() {
    const errors = new Array<string>()
    if (!this.$v.selectedRoom.$dirty) {
      return errors
    }
    if (!this.$v.selectedRoom.required) {
      errors.push(this.$t('projectDesignPackage.errors.room.required').toString())
    }
    return errors
  }

  get styleErrors() {
    const errors = new Array<string>()
    if (!this.$v.selectedStyle.$dirty) {
      return errors
    }
    if (!this.$v.selectedStyle.required) {
      errors.push(this.$t('projectDesignPackage.errors.style.required').toString())
    }
    return errors
  }

  get brandErrors() {
    const errors = new Array<string>()
    if (!this.$v.selectedBrand.$dirty) {
      return errors
    }
    if (!this.$v.selectedBrand.required) {
      errors.push(this.$t('projectDesignPackage.errors.brand.required').toString())
    }
    return errors
  }

  get productPackageErrors() {
    const errors = new Array<string>()
    if (!this.$v.selectedProductPackage.$dirty) {
      return errors
    }
    if (!this.$v.selectedProductPackage.required) {
      errors.push(this.$t('projectDesignPackage.errors.productPackage.required').toString())
    }
    return errors
  }

  get nameErrors() {
    const errors = new Array<string>()
    if (!this.$v.name.$dirty) {
      return errors
    }
    if (!this.$v.name.alphaNumSpace) {
      errors.push(this.$t('projectDesignPackage.errors.name.alphaNumSpace').toString())
    }
    if (!this.$v.name.required) {
      errors.push(this.$t('projectDesignPackage.errors.name.required').toString())
    }
    return errors
  }

  get positionErrors() {
    const errors = new Array<string>()
    if (!this.$v.position.$dirty) {
      return errors
    }
    if (!this.$v.position.integer) {
      errors.push(this.$t('projectDesignPackage.errors.position.integer').toString())
    }
    if (!this.$v.position.minValue) {
      errors.push(this.$t('projectDesignPackage.errors.position.minValue').toString())
    }
    if (!this.$v.position.required) {
      errors.push(this.$t('projectDesignPackage.errors.position.required').toString())
    }
    return errors
  }

  get priceErrors() {
    const errors = new Array<string>()
    if (!this.$v.totalPrice.$dirty) {
      return errors
    }
    if (!this.$v.totalPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.totalPrice.money').toString())
    }
    if (!this.$v.totalPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.totalPrice.required').toString())
    }
    return errors
  }

  get productsAndInstallationMaterialsPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.productsAndInstallationMaterialsPrice.$dirty) {
      return errors
    }
    if (!this.$v.productsAndInstallationMaterialsPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.productsAndInstallationMaterialsPrice.money').toString())
    }
    if (!this.$v.productsAndInstallationMaterialsPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.productsAndInstallationMaterialsPrice.required').toString())
    }
    return errors
  }

  get coordinationCommissionInstallerPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.coordinationCommissionInstallerPrice.$dirty) {
      return errors
    }
    if (!this.$v.coordinationCommissionInstallerPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.coordinationCommissionInstallerPrice.money').toString())
    }
    if (!this.$v.coordinationCommissionInstallerPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.coordinationCommissionInstallerPrice.required').toString())
    }
    return errors
  }

  get coordinationCommissionBuilderPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.coordinationCommissionBuilderPrice.$dirty) {
      return errors
    }
    if (!this.$v.coordinationCommissionBuilderPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.coordinationCommissionBuilderPrice.money').toString())
    }
    if (!this.$v.coordinationCommissionBuilderPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.coordinationCommissionBuilderPrice.required').toString())
    }
    return errors
  }

  get installationConstructionPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.installationConstructionPrice.$dirty) {
      return errors
    }
    if (!this.$v.installationConstructionPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.installationConstructionPrice.money').toString())
    }
    if (!this.$v.installationConstructionPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.installationConstructionPrice.required').toString())
    }
    return errors
  }

  get tilesAndFastenersPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.tilesAndFastenersPrice.$dirty) {
      return errors
    }
    if (!this.$v.tilesAndFastenersPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.tilesAndFastenersPrice.money').toString())
    }
    if (!this.$v.tilesAndFastenersPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.tilesAndFastenersPrice.required').toString())
    }
    return errors
  }

  get wInstallationPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.wInstallationPrice.$dirty) {
      return errors
    }
    if (!this.$v.wInstallationPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.wInstallationPrice.money').toString())
    }
    if (!this.$v.wInstallationPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.wInstallationPrice.required').toString())
    }
    return errors
  }

  get eInstallationPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.eInstallationPrice.$dirty) {
      return errors
    }
    if (!this.$v.eInstallationPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.eInstallationPrice.money').toString())
    }
    if (!this.$v.eInstallationPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.eInstallationPrice.required').toString())
    }
    return errors
  }

  get cvInstallationPriceErrors() {
    const errors = new Array<string>()
    if (!this.$v.cvInstallationPrice.$dirty) {
      return errors
    }
    if (!this.$v.cvInstallationPrice.money) {
      errors.push(this.$t('projectDesignPackage.errors.cvInstallationPrice.money').toString())
    }
    if (!this.$v.cvInstallationPrice.required) {
      errors.push(this.$t('projectDesignPackage.errors.cvInstallationPrice.required').toString())
    }
    return errors
  }

  validations() {
    return {
      selectedRoom: { required },
      selectedStyle: {
        required: requiredIf(() => {
          return !this.isStandard
        })
      },
      selectedBrand: {
        required: requiredIf(() => {
          return !this.isStandard
        })
      },
      selectedProductPackage: {
        required
      },
      name: {
        alphaNumSpace,
        required: requiredIf(() => {
          return !this.isProductPackagesProject && !this.isStandard
        })
      },
      isStandard: {},
      hasBath: {},
      hasToiletLabel: {},
      isXL: {},
      position: {
        integer,
        minValue: minValue(0),
        required
      },
      totalPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      productsAndInstallationMaterialsPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      coordinationCommissionInstallerPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      coordinationCommissionBuilderPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      installationConstructionPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      tilesAndFastenersPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      wInstallationPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      eInstallationPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      cvInstallationPrice: {
        money: money(),
        required: requiredIf(() => {
          return this.isStandard
        })
      },
      combinations: {
        selectedCombination: selectedCombination,
        $each: {
          values: {
            $each: {
              item2: {
                money: moneyIf,
                lengthMeasure: lengthMeasureIf,
                areaMeasure: areaMeasureIf,
                required: requiredIf(function (a) {
                  return !a.isOptional && RequiredDataTypes.has(a.dataType)
                })
              }
            }
          },
          categoriesWithAssignedProductSets: {
            $each: {
              selectedDefaultProductSetsForCategory: {
                productSetIds: {
                  required: requiredIf(this.selectedDefaultProduct)
                },
                setAsProjectDefault: {}
              },
              selectedPreselectedProductSetsForCategory: {
                productSetIds: {
                  required: requiredIf(this.selectedPreselectedProduct)
                }
              }
            }
          }
        }
      }
    }
  }
  //#endregion validations & errors

  //#region event handlers
  setPrices() {
    this.totalPrice = this.summedTotalPrice > 0 ? this.summedTotalPrice?.toFixed(2)?.toString() : '0'
  }

  clickSave() {
    if (this.disabledSave || !this.isEditable || this.isReadOnly) {
      return false
    }

    this.$v.$touch()

    // the touch above takes too long, so wait till DOM is updated to scroll to invalid fields
    this.$nextTick(() => {
      // If there are any missing required fields, scroll to the first one
      if (this.$v.$anyDirty) {
        // Find element with class "error-text" and scroll to it
        const firstErrorField = document.querySelector('.error--text')
        if (firstErrorField) {
          firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    })

    if (this.$v.combinations.$pending || this.$v.combinations.$error) {
      return false
    }

    if (this.isProductPackagesProject && (this.$v.combinations.$pending || this.$v.combinations.$error)) {
      return false
    }
    if (this.$v.selectedRoom.$pending || this.$v.selectedRoom.$error) return false
    if (this.isStylesProject && (this.$v.selectedStyle.$pending || this.$v.selectedStyle.$error)) return false
    if (this.isBrandsProject && (this.$v.selectedBrand.$pending || this.$v.selectedBrand.$error)) return false
    if (
      this.isProductPackagesProject &&
      (this.$v.selectedProductPackage.$pending || this.$v.selectedProductPackage.$error)
    )
      return false
    if (!this.isProductPackagesProject && (this.$v.name.$pending || this.$v.name.$error)) return false
    if (this.$v.position.$pending || this.$v.position.$error) return false
    if (this.$v.totalPrice.$pending || this.$v.totalPrice.$error) return false
    if (this.$v.productsAndInstallationMaterialsPrice.$pending || this.$v.productsAndInstallationMaterialsPrice.$error)
      return false
    if (this.$v.coordinationCommissionInstallerPrice.$pending || this.$v.coordinationCommissionInstallerPrice.$error)
      return false
    if (this.$v.coordinationCommissionBuilderPrice.$pending || this.$v.coordinationCommissionBuilderPrice.$error)
      return false
    if (this.$v.installationConstructionPrice.$pending || this.$v.installationConstructionPrice.$error) return false
    if (this.$v.tilesAndFastenersPrice.$pending || this.$v.tilesAndFastenersPrice.$error) return false
    if (this.$v.wInstallationPrice.$pending || this.$v.wInstallationPrice.$error) return false
    if (this.$v.eInstallationPrice.$pending || this.$v.eInstallationPrice.$error) return false
    if (this.$v.cvInstallationPrice.$pending || this.$v.cvInstallationPrice.$error) return false
    if (!this.designPackageForm?.validate()) return false

    if (!this.name?.trim()) {
      let name = this.rooms?.find((r) => r.id === this.selectedRoom)?.name ?? ''
      if (this.isXL) name += '-XL'
      if (this.hasBath) name += '-Bath'
      if (this.hasToiletLabel) name += '-Toilet'
      this.name = name.replace(/^-+|-+$/g, '') // trim '-' char
    }

    this.disabledSave = true
    this.isSaving = true
    ;(this.designPackageId ? this.updateDesignPackage() : this.createDesignPackage())
      .then((result) => {
        if (result === false) return

        this.$v.$reset()
        this.$router.push({ name: 'project-list-design-packages' })
      })
      .finally(() => {
        this.disabledSave = false
        this.isSaving = false
      })
  }

  createDesignPackage() {
    return this.$apolloMutate<CreateDesignPackageMutation, CreateDesignPackageMutationVariables>({
      mutation: CreateDesignPackageDocument,
      variables: {
        projectId: this.projectId,
        roomId: this.selectedRoom,
        styleId: this.isStylesProject && !this.isStandard ? this.selectedStyle : GuidEmpty,
        brandId: this.isBrandsProject && !this.isStandard ? this.selectedBrand : GuidEmpty,
        productPackageId: this.isProductPackagesProject ? this.selectedProductPackage : GuidEmpty,
        designPackageName: this.name,
        priceCurrency: Currency.Eur,
        priceAmount: this.totalPrice,
        position: parseInt(this.position.toString()),
        hasBath: this.hasBath,
        hasToiletLabel: this.hasToiletLabel,
        isXL: this.isXL,
        isStandard: this.isStandard,
        priceDetails: this.getPriceDetails(),
        hasBathtub: this.hasCombination(ProductCombinationType.Bathtub),
        hasFoutain: this.hasCombination(ProductCombinationType.Fountain),
        hasRadiator: this.hasCombination(ProductCombinationType.Radiator),
        hasShower: this.hasCombination(ProductCombinationType.Shower),
        hasTiles: this.hasCombination(ProductCombinationType.Tiles),
        hasToilet: this.hasCombination(ProductCombinationType.Toilet),
        hasUtilities: this.hasCombination(ProductCombinationType.Utilities),
        hasWashbasin: this.hasCombination(ProductCombinationType.Washbasin),
        designPackageRestrictionValues: this.designPackageRestrictionValues,
        designPackageDefaults: this.designPackageDefaults,
        isTilesToCeiling: this.isTilesToCeiling,
        designPackageWalls: this.selectedCombinations
          ?.flatMap((x) => x.walls)
          .map((wall) => ({
            ...wall,
            area: Number(wall.area),
            ceilingArea: Number(wall.ceilingArea),
            profileLength: Number(wall.profileLength),
            ceilingLength: Number(wall.ceilingLength),
            accentTileDefaultProductSetId:
              wall.accentTileDefaultProductSetId === GuidEmpty ? null : wall.accentTileDefaultProductSetId,
            accentTilePreselectedProductSetId:
              wall.accentTilePreselectedProductSetId === GuidEmpty ? null : wall.accentTilePreselectedProductSetId
          }))
      },
      error: 'E4116'
    })
  }

  updateDesignPackage() {
    return this.$apolloMutate<UpdateDesignPackageMutation, UpdateDesignPackageMutationVariables>({
      mutation: UpdateDesignPackageDocument,
      variables: {
        projectId: this.projectId,
        designPackageId: this.designPackageId,
        designPackageName: this.name,
        priceCurrency: Currency.Eur,
        priceAmount: this.totalPrice,
        position: parseInt(this.position.toString()),
        hasBath: this.hasBath,
        hasToiletLabel: this.hasToiletLabel,
        isXL: this.isXL,
        isStandard: this.isStandard,
        hasWashbasin: this.hasCombination(ProductCombinationType.Washbasin),
        hasShower: this.hasCombination(ProductCombinationType.Shower),
        hasToilet: this.hasCombination(ProductCombinationType.Toilet),
        hasBathtub: this.hasCombination(ProductCombinationType.Bathtub),
        hasFoutain: this.hasCombination(ProductCombinationType.Fountain),
        hasTiles: this.hasCombination(ProductCombinationType.Tiles),
        hasRadiator: this.hasCombination(ProductCombinationType.Radiator),
        hasUtilities: this.hasCombination(ProductCombinationType.Utilities),
        priceDetails: this.getPriceDetails(),
        designPackageRestrictionValues: this.designPackageRestrictionValues,
        designPackageDefaults: this.designPackageDefaults,
        designPackagePreselected: this.designPackagePreselected,
        isTilesToCeiling: this.isTilesToCeiling,
        designPackageWalls: this.selectedCombinations
          ?.flatMap((x) => x.walls)
          .map(
            (wall): DesignPackageWallDtoInput => ({
              ...wall,
              id: wall.id,
              area: Number(wall.area),
              ceilingArea: Number(wall.ceilingArea),
              profileLength: Number(wall.profileLength),
              ceilingLength: Number(wall.ceilingLength),
              accentTileDefaultProductSetId:
                wall.accentTileDefaultProductSetId === GuidEmpty ? null : wall.accentTileDefaultProductSetId,
              accentTilePreselectedProductSetId:
                wall.accentTilePreselectedProductSetId === GuidEmpty ? null : wall.accentTilePreselectedProductSetId
            })
          )
      },
      error: 'E4125'
    })
  }

  selectedCombinationsChanged(selectedCombinationTypes: SelectedCombination[]) {
    this.combinations = selectedCombinationTypes
    this.combinations.forEach((elem) => {
      if (elem.isSelected && !elem.combinationInfo) {
        this.fetchCombinationItem(elem)
        this.fetchDefaultProductSets(elem)
      }
    })
  }
  //#endregion event handlers

  //#region methods
  fetchDesignPackageItems() {
    this.$apollo.addSmartQuery<GetProjectOptionsForDesignPackageQuery, GetProjectOptionsForDesignPackageQueryVariables>(
      QUERIES.GetProjectOptionsForDesignPackageQuery,
      {
        query: GetProjectOptionsForDesignPackageDocument,
        variables: () => ({
          projectId: this.projectId
        }),
        result: (result) => {
          this.fetchedData = result.data

          const project = result.data.project

          if (!project?.rooms?.length) {
            this.$showNotificationDialog({
              Code: 'N4003'
            })
          }

          if (this.isStylesProject && !project?.assignedStyles?.length) {
            this.$showNotificationDialog({
              Code: 'N4002'
            })
          }

          if (this.isBrandsProject && !project?.assignedBrands?.length) {
            this.$showNotificationDialog({
              Code: 'N4009'
            })
          }

          if (this.isProductPackagesProject && !project?.assignedProductPackages?.length) {
            this.$showNotificationDialog({
              Code: 'N4010'
            })
          }

          this.isEditable = !this.designPackageId || project?.status === ProjectStatus.Preview
        },
        update: (data) => data,
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4124',
            Message: error.message,
            Sub: !this.designPackageId ? 'A' : 'B'
          })
        }
      }
    )
  }

  fetchDesignPackageValues() {
    this.$apollo.addSmartQuery<GetDesignPackageQuery, GetDesignPackageQueryVariables>(QUERIES.GetDesignPackageQuery, {
      query: GetDesignPackageDocument,
      variables: () => ({
        designPackageId: this.designPackageId
      }),
      result: (result) => {
        const designPackage = result?.data?.designPackage
        if (designPackage) {
          this.selectedRoom = designPackage.room.id
          this.selectedStyle = designPackage.style?.id
          this.selectedBrand = designPackage.brand?.id
          this.selectedProductPackage =
            designPackage.productPackage?.id ??
            designPackage.style?.productPackageId ??
            designPackage.brand?.productPackageId
          this.selectedPriceVariant = designPackage.priceVariant ?? undefined
          this.name = designPackage.name
          this.totalPrice = designPackage.price.amount.toString()
          this.position = designPackage.position
          this.hasBath = designPackage.hasBath
          this.hasToiletLabel = designPackage.hasToiletLabel
          this.isXL = designPackage.isXL
          this.isStandard = designPackage.isStandard
          this.isTilesToCeiling = designPackage.isTilesToCeiling
          this.assignedRestrictions = (designPackage.assignedRestrictions ?? []) as DesignPackageProductSetRestriction[]
          this.assignCosts(designPackage.assignedCosts as DesignPackageCost[])
          this.assignedDefaultProducts = (designPackage.assignedDefaultProducts ??
            []) as DesignPackageDefaultProductSet[]
          this.assignedPreselectedProducts = (designPackage.assignedPreselectedProductSets ??
            []) as DesignPackagePreselectedProductSet[]
          this.assignedWalls = this.getDefaultWalls(designPackage.assignedWalls as DesignPackageWall[])

          this.combinations.forEach((element) => {
            element.isSelected = this.isCombinationPreSelected(element.id)
            if (element.combinationType === ProductCombinationType.Tiles) {
              element.walls = this.getDefaultWallsFromInput(this.assignedWalls)
            }
          })
          this.selectedCombinationsChanged(this.combinations)
        } else {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4127',
            Message: `${this.projectId}/${this.designPackageId}`
          })
        }
      },
      update: (data) => data,
      skip: () => !this.designPackageId,
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4120',
          Message: error.message,
          Sub: 'C'
        })
      }
    })
  }

  fetchProductCombinations() {
    this.$apollo.addSmartQuery<ProductPackageCombinationsQuery, ProductPackageCombinationsQueryVariables>(
      QUERIES.ProductPackageCombinationsQuery,
      {
        query: ProductPackageCombinationsDocument,
        update: (data) => data,
        result: (result) => {
          result.data.masterProductPackage?.availableCombinations?.forEach((element) => {
            this.combinations.push({
              id: element.id,
              isSelected: this.isCombinationPreSelected(element.id),
              combinationType: element.combinationType,
              combinationInfo: null,
              values: [],
              categoriesWithAssignedProductSets: [],
              walls:
                element.combinationType === ProductCombinationType.Tiles
                  ? this.getDefaultWalls(this.assignedWalls as DesignPackageWall[])
                  : []
            })
          })
          this.selectedCombinationsChanged(this.combinations)
        }
      }
    )
  }

  fetchCategoryCMSContent() {
    this.$apollo.addSmartQuery<CategoriesQueryQuery, CategoriesQueryQueryVariables>(QUERIES.CategoryCMSQuery, {
      query: CategoriesQueryDocument,
      variables: () => ({
        defaultLang: 'nl',
        currentLang: this.language
      }),
      update: (data) => data,
      result: (result) => {
        if (result) {
          this.categoriesCMSContent = (result.data?.category ?? []) as CategoryFieldsFragment[]
        }
      },
      skip: () => {
        return !this.language
      }
    })
  }

  getPriceDetails() {
    return [
      {
        item1: CostType.ProductsAndInstallationMaterialsCosts,
        item2: this.productsAndInstallationMaterialsPrice
      },
      {
        item1: CostType.CoordinationCommissionInstallerCosts,
        item2: this.coordinationCommissionInstallerPrice
      },
      {
        item1: CostType.CoordinationCommissionBuilderCosts,
        item2: this.coordinationCommissionBuilderPrice
      },
      {
        item1: CostType.InstallationConstructionCosts,
        item2: this.installationConstructionPrice
      },
      {
        item1: CostType.TilesAndFastenersCosts,
        item2: this.tilesAndFastenersPrice
      },
      {
        item1: CostType.WInstallationCosts,
        item2: this.wInstallationPrice
      },
      {
        item1: CostType.EInstallationCosts,
        item2: this.eInstallationPrice
      },
      {
        item1: CostType.CvInstallationCosts,
        item2: this.cvInstallationPrice
      }
    ]
  }

  getLayoutsForRoom(room: Room) {
    return room?.layoutRooms?.map((layoutRoom) => layoutRoom?.layout?.name)?.join(', ')
  }

  hasCombination(combinationType: ProductCombinationType) {
    return this.combinations.find((x) => x.combinationType === combinationType)?.isSelected
  }

  getCombinationValidation(combination: SelectedCombination) {
    const index = this.combinations.indexOf(combination)
    return this.$v.combinations.$each?.[index]
  }

  isCombinationPreSelected(id: string): boolean {
    return !!this.assignedRestrictions?.find((x) => x?.productCategory?.productCombination?.id == id)
  }

  fetchCombinationItem(combination: SelectedCombination) {
    this.$apollo
      .query<GetDesignPackageRestrictionKeysQuery, GetDesignPackageRestrictionKeysQueryVariables>({
        query: GetDesignPackageRestrictionKeysDocument,
        variables: {
          combinationType: combination.combinationType,
          language: this.language
        }
      })
      .then((response) => {
        if (!response?.data) throw new Error()

        combination.combinationInfo = response.data
        combination.values = response.data.designPackageProductSetRestrictionInfoKeys.map(
          (x) =>
            ({
              item1: x.id,
              item2: this.getDefaultValues(x as DesignPackageProductSetRestrictionInfoKey),
              dataType: x.productSetInfoKey?.dataType,
              isOptional: x.isOptional
            } as FieldInformation)
        )
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4156',
          Message: reason ?? ''
        })
      })
  }

  fetchDefaultProductSets(combination: SelectedCombination) {
    this.$apollo
      .query<GetProductSetsDefaultsQuery, GetProductSetsDefaultsQueryVariables>({
        query: GetProductSetsDefaultsDocument,
        variables: {
          combinationType: combination.combinationType
        }
      })
      .then((response) => {
        if (!response?.data) throw new Error()

        // Get the categories with their default productSets from this product package
        // We set up this projectWideDefaultProductSet object for easier mapping/validation
        combination.categoriesWithAssignedProductSets =
          response.data?.masterProductPackage?.assignedProductPackageCategories
            .map((x) => {
              // For the default, first check if there is already a default in this (existing) design package,
              // Otherwise take the project wide default (if there is one).
              const selectedDefaultsForExistingDesignPackage = this.assignedDefaultProducts?.filter(
                (y) => y?.productCategory?.id === x?.productCategory?.id
              )
              // map those to an array of just the product set id's
              const selectedDefaultProductSetIds: string[] = selectedDefaultsForExistingDesignPackage?.flatMap(
                (y) => y?.productSet?.id
              )

              // If there's no selected product sets for this (existing) design package, or if we are creating a new design package
              // we need to check if there are project wide selected default product sets
              const projectWideSelectedDefaultProductSets = this.project?.assignedDefaultProducts.filter(
                (y) => y?.productCategoryId === x.productCategory?.id
              )
              // map those to an array of just the product set id's
              const projectWideSelectedDefaultProductSetIds: string[] =
                projectWideSelectedDefaultProductSets?.flatMap((y) => y?.productSetId) ?? []

              return {
                ...x,
                selectedDefaultProductSetsForCategory: {
                  productCategoryId: x.productCategory?.id,
                  productSetIds: selectedDefaultProductSetIds.length
                    ? selectedDefaultProductSetIds
                    : this.isEditMode
                    ? []
                    : projectWideSelectedDefaultProductSetIds,
                  setAsProjectDefault: false
                },
                selectedPreselectedProductSetsForCategory: {
                  productCategoryId: x.productCategory?.id,
                  productSetIds: ''
                }
              }
            })
            ?.sort((a, b) => (a.productCategory?.position ?? 0) - (b.productCategory?.position ?? 0)) ?? []
      })
      .then(() => this.fetchPreselectedProductSets(combination))
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4157',
          Message: reason ?? ''
        })
      })
  }

  fetchPreselectedProductSets(combination: SelectedCombination) {
    if (this.isProductPackagesProject || this.isStandard) return

    this.$apollo
      .query<GetProductSetsPreselectedQuery, GetProductSetsPreselectedQueryVariables>({
        query: GetProductSetsPreselectedDocument,
        variables: {
          combinationType: combination.combinationType,
          productPackageId: this.selectedProductPackage,
          priceVariant: this.selectedPriceVariant
        }
      })
      .then((response) => {
        if (!response?.data) throw new Error()

        combination.categoriesWithAssignedProductSets = combination.categoriesWithAssignedProductSets.map((x) => {
          // For the default, first check if there is already a default in this (existing) design package,
          // Otherwise take the project wide default (if there is one).
          const selectedPreselectedForExistingDesignPackage = this.assignedPreselectedProducts?.filter(
            (y) => y?.productCategory?.id === x?.productCategory?.id
          )
          // map those to an array of just the product set id's
          const selectedPreselectedProductSetIds: string[] = selectedPreselectedForExistingDesignPackage?.flatMap(
            (y) => y?.productSet?.id
          )

          const filteredPreselectedProductSet = response?.data.productPackage?.assignedProductPackageCategories
            .filter((y) =>
              y?.assignedPreselectedProductSets?.items?.some((z) =>
                z?.assignedPriceVariants?.some((z2) => z2?.productCategoryId === x?.productCategory?.id)
              )
            )
            .map((ss) =>
              ss.assignedPreselectedProductSets?.items
                ?.filter((zz) =>
                  zz?.assignedPriceVariants?.some((z2) => z2?.productCategoryId === x?.productCategory?.id)
                )
                .map((ee) => ({
                  productSet: ee?.productSet ?? { id: '', name: '' },
                  assignedPriceVariants: []
                }))
            )[0]

          return {
            ...x,
            selectedPreselectedProductSetsForCategory: {
              productCategoryId: x.productCategory?.id,
              productSetIds: selectedPreselectedProductSetIds.length ? selectedPreselectedProductSetIds[0] : ''
            },
            assignedPreselectedProductSets: {
              productCategory: response?.data.productPackage?.assignedProductPackageCategories.find(
                (y) => y?.productCategory?.id === x?.productCategory?.id
              )?.productCategory,
              items: filteredPreselectedProductSet ?? []
            }
          }
        })
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4157',
          Message: reason ?? ''
        })
      })
  }

  getDefaultValues(x: DesignPackageProductSetRestrictionInfoKey) {
    const preSelectedValues = this.assignedRestrictions?.filter(
      (y) =>
        y?.productCategory?.id.toString().toLowerCase() == x.productCategory?.id.toString().toLowerCase() &&
        y?.productSetInfoKey == x.productSetInfoKey?.key
    )

    if (preSelectedValues && preSelectedValues.length) {
      // Gets the standard/min restriction value if there are more than one rule for the same restriction key, otherwise gets the first value.
      // Example: DEPTH
      //    Greater Than: x
      //    Lower Than: y
      //    returns: x
      const preSelected =
        preSelectedValues.find((y) => y?.productSetInfoCompareOperation === ProductSetInfoCompareOperation.Gt) ??
        preSelectedValues[0]

      if (preSelected) {
        if (!x.isOptional && x?.productSetInfoKey?.dataType == ProductSetInfoKeyDataType.Bool) {
          return preSelected.productSetInfoCompareValue == '1'
        }

        return preSelected.productSetInfoCompareValue
      }
    }
    // an exception for MultiEnum field ALLOWED_SHOWER_ENCLOSURE_TYPE so that on create we star with all options selected
    else if (this.isCreateNew && x.productSetInfoKey?.key === ProductSetInfoKeyType.AllowedShowerEnclosureType) {
      return x.productSetInfoKey.possibleValues.map((val) => val.key).reduce((prev, curr) => curr + '|' + prev)
    }

    if (!x.isOptional && x?.productSetInfoKey?.dataType == ProductSetInfoKeyDataType.Bool) {
      return false
    }

    return ''
  }

  assignCosts(assignedCosts: DesignPackageCost[] | null) {
    assignedCosts?.forEach((element) => {
      switch (element?.costType) {
        case CostType.ProductsAndInstallationMaterialsCosts:
          this.productsAndInstallationMaterialsPrice = element.cost.amount.toString()
          break

        case CostType.CoordinationCommissionCosts:
        case CostType.CoordinationCommissionInstallerCosts:
          this.coordinationCommissionInstallerPrice = element.cost.amount.toString()
          break

        case CostType.CoordinationCommissionBuilderCosts:
          this.coordinationCommissionBuilderPrice = element.cost.amount.toString()
          break

        case CostType.InstallationConstructionCosts:
          this.installationConstructionPrice = element.cost.amount.toString()
          break

        case CostType.TilesAndFastenersCosts:
          this.tilesAndFastenersPrice = element.cost.amount.toString()
          break

        case CostType.WInstallationCosts:
          this.wInstallationPrice = element.cost.amount.toString()
          break

        case CostType.EInstallationCosts:
          this.eInstallationPrice = element.cost.amount.toString()
          break

        case CostType.CvInstallationCosts:
          this.cvInstallationPrice = element.cost.amount.toString()
          break

        default:
          break
      }
    })
  }

  getDefaultWalls(existingWalls: DesignPackageWall[]): DesignPackageWallDtoInput[] {
    if (!this.isEditMode && !this.isReadOnly) {
      return ['A', 'B', 'C', 'D'].map((defaultName: string) => ({
        name: defaultName,
        area: 0,
        ceilingArea: 0,
        profileLength: 0,
        ceilingLength: 0,
        isAccentWall: false,
        accentTileDefaultProductSetId: null,
        accentTilePreselectedProductSetId: null
      }))
    }

    return existingWalls.map((x) => ({
      id: x.id,
      area: x.area,
      ceilingArea: x.ceilingArea,
      ceilingLength: x.ceilingLength,
      isAccentWall: x.isAccentWall,
      name: x.name,
      profileLength: x.profileLength,
      accentTileDefaultProductSetId:
        this.assignedDefaultProducts.find((z) => z.designPackageWall?.id == x.id)?.productSet?.id ??
        (x.isAccentWall ? GuidEmpty : null),
      accentTilePreselectedProductSetId:
        this.assignedPreselectedProducts.find((y) => y.designPackageWall?.id == x.id)?.productSet?.id ??
        (x.isAccentWall ? GuidEmpty : null)
    }))
  }

  getDefaultWallsFromInput(existingWalls: DesignPackageWallDtoInput[]): DesignPackageWallDtoInput[] {
    if (!this.isEditMode && !this.isReadOnly) {
      return ['A', 'B', 'C', 'D'].map((defaultName: string) => ({
        name: defaultName,
        area: 0,
        ceilingArea: 0,
        profileLength: 0,
        ceilingLength: 0,
        isAccentWall: false,
        accentTileDefaultProductSetId: null,
        accentTilePreselectedProductSetId: null
      }))
    }

    return existingWalls.map((x) => ({
      id: x.id,
      area: x.area,
      ceilingArea: x.ceilingArea,
      ceilingLength: x.ceilingLength,
      isAccentWall: x.isAccentWall,
      name: x.name,
      profileLength: x.profileLength,
      accentTileDefaultProductSetId: x.accentTileDefaultProductSetId,
      accentTilePreselectedProductSetId: x.accentTilePreselectedProductSetId
    }))
  }

  isBasicInRoom(combination: SelectedCombination) {
    const designPackageProductSetRestrictionInfoKeysArray =
      combination.combinationInfo?.designPackageProductSetRestrictionInfoKeys ?? []
    const restriction = designPackageProductSetRestrictionInfoKeysArray.find(
      (x) => x.productSetInfoKey?.key === ProductSetInfoKeyType.BasicIncluded
    )
    if (!restriction) return false

    return combination.values[designPackageProductSetRestrictionInfoKeysArray.indexOf(restriction)].item2
  }

  selectedDefaultProduct(defaultProduct: AddDefaultProductSetDtoInput) {
    const combination = this.combinations.find((x) =>
      x.combinationInfo?.designPackageProductSetRestrictionInfoKeys.find(
        (y) => y.productCategory?.id === defaultProduct.productCategoryId
      )
    )

    const category = combination?.categoriesWithAssignedProductSets.find(
      (cat) => cat.productCategory?.id === defaultProduct.productCategoryId
    )

    return (
      !!combination?.isSelected &&
      this.isBasicInRoom(combination) &&
      !(category?.productCategory?.isSelectionOptional && isOptionalButMandatoryCategory(category?.productCategory?.id))
    )
  }

  selectedPreselectedProduct(preselectedProduct: AddPreselectedProductSetDtoInput) {
    const combination = this.combinations.find((x) =>
      x.combinationInfo?.designPackageProductSetRestrictionInfoKeys.find(
        (y) => y.productCategory?.id === preselectedProduct.productCategoryId
      )
    )

    const category = combination?.categoriesWithAssignedProductSets.find(
      (cat) => cat.productCategory?.id === preselectedProduct.productCategoryId
    )

    return (
      this.isPriceVariant &&
      !!combination?.isSelected &&
      !(category?.productCategory?.isSelectionOptional && isOptionalButMandatoryCategory(category?.productCategory?.id))
    )
  }
  //#endregion methods
}
