
import { Component, Vue } from 'vue-property-decorator'
import AddBrandDialog from '@/components/brands/AddBrandComponent.vue'
import CopyBrandDialog from '@/components/projectBrands/CopyBrandDialog.vue'
import UpdateProductPackageDialog from '@/components/updateProductPackageDialog/UpdateProductPackageDialog.vue'
import QUERIES from '@/queries/queries'
import {
  GetBrandPageByAliasQueryQuery,
  GetBrandPageByAliasQueryDocument,
  GetBrandPageByAliasQueryQueryVariables
} from './gql/__generated__/BrandPageQuery'
import {
  DeleteBrandMutation,
  DeleteBrandMutationVariables,
  DeleteBrandDocument
} from './gql/__generated__/BrandsViewMutation'
import {
  BrandsViewQueryQuery,
  BrandsViewQueryDocument,
  BrandsViewQueryQueryVariables,
  BrandFragment
} from './gql/__generated__/BrandsViewQuery'
import { Badkamercore_ProjectType } from '@/__generated__/globalTypes'
import { RouteNames } from '@/router/routeNames'
import {
  DeleteProductPackageMutation,
  DeleteProductPackageMutationVariables,
  DeleteProductPackageDocument
} from '../productPackageListView/gql/__generated__/DeleteProductPackage.mutation'
import { BrandFragmentWithUpdateStatus } from './types'

@Component({
  components: {
    AddBrandDialog,
    CopyBrandDialog,
    UpdateProductPackageDialog
  }
})
export default class BrandsView extends Vue {
  headers = [
    {
      text: '',
      value: 'productPackage.isMain',
      sortable: true,
      align: 'center',
      cellClass: 'c-brand-listing__icon-column'
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: '', value: 'actions', sortable: false, align: 'end' }
  ]

  brands: BrandFragmentWithUpdateStatus[] | null = null
  showForm = false
  areImportsLoading = false

  routeNames = RouteNames
  Badkamercore_ProjectType = Badkamercore_ProjectType

  showUpdateProductPackageDialog = false
  brandProductPackageToUpdateId = ''
  brandToUpdateName = ''

  // Data for copying a brand
  showBrandCopyModal = false
  brandToCopyId = ''
  brandToCopyName = ''

  downloadsInProgress: Record<string, boolean> = {}

  created() {
    this.fetchData()
  }

  get isNotAdmin() {
    return !this.$store.getters.isAdmin
  }

  assignRowClasses(item: BrandFragmentWithUpdateStatus) {
    return { 'c-brand-listing__row': item.isBeingUpdated }
  }

  openUpdateProductPackageDialog(item: BrandFragmentWithUpdateStatus) {
    this.showUpdateProductPackageDialog = true
    this.brandProductPackageToUpdateId = item.productPackageId
    this.brandToUpdateName = item.name
  }

  fetchData() {
    this.$apollo.addSmartQuery<BrandsViewQueryQuery, BrandsViewQueryQueryVariables>(QUERIES.BrandsViewQuery, {
      query: BrandsViewQueryDocument,
      update: (data) => data,
      result: (result) => {
        this.brands = result.data?.brands.map((brand) => ({
          ...brand,
          isBeingUpdated: false
        }))
      },
      error: (error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4140',
          Message: error
        })
      }
    })
  }

  openForm() {
    this.showForm = true
  }

  closeForm() {
    this.showForm = false
  }

  downloadImportFile(item: BrandFragmentWithUpdateStatus) {
    this.$set(this.downloadsInProgress, item.id, true)

    this.$fileApi
      .downloadFile(`/importProductPackage/${item.productPackageId}/${item.name}`, item.name)
      .catch((error) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4244',
          Message: error
        })
      })
      .finally(() => {
        this.$set(this.downloadsInProgress, item.id, false)
      })
  }

  deleteBrand(item: BrandFragmentWithUpdateStatus) {
    if (item.productPackageId) {
      this.$apolloMutate<DeleteProductPackageMutation, DeleteProductPackageMutationVariables>({
        mutation: DeleteProductPackageDocument,
        variables: {
          productPackageId: item.productPackageId
        },
        refetchQueries: [QUERIES.BrandsViewQuery],
        error: 'E4141'
      })
    } else {
      this.$apolloMutate<DeleteBrandMutation, DeleteBrandMutationVariables>({
        mutation: DeleteBrandDocument,
        variables: {
          brandId: item.id
        },
        refetchQueries: [QUERIES.BrandsViewQuery],
        error: 'E4141'
      })
    }
  }

  openBrandCopyModal(item: BrandFragmentWithUpdateStatus) {
    this.showBrandCopyModal = true
    this.brandToCopyId = item.id
    this.brandToCopyName = item.name + this.$t('brand.copy.form.defaultFieldValue')
  }

  navigateToCms(item: BrandFragment) {
    this.$apollo
      .query<GetBrandPageByAliasQueryQuery, GetBrandPageByAliasQueryQueryVariables>({
        query: GetBrandPageByAliasQueryDocument,
        variables: {
          aliasId: item.id
        }
      })
      .catch((reason) => {
        this.$store.dispatch('showErrorDialog', {
          Code: 'E4142',
          Message: reason
        })
      })
      .then((response) => {
        if (response) {
          const host = process.env.VUE_APP_CMS_URL
          const contentItemId = response.data.brandPage?.[0]?.contentItemId
          if (contentItemId) {
            const url = `${host}/Admin/Contents/ContentItems/${contentItemId}/Edit?returnUrl=%2FAdmin%2FContents%2FContentItems`
            window.open(url, 'editStyleInCms')
          } else {
            this.$store.dispatch('showErrorDialog', {
              Code: 'E4143'
            })
          }
        }
      })
  }

  get brandNames() {
    return this.brands?.map((item) => item.name.replace(/[^a-zA-Z0-9]/g, ''))
  }

  confirmDeleteBrand(item: BrandFragmentWithUpdateStatus) {
    this.$showConfirmationDialog({
      awaitConfirm: true,
      Callback: async () => {
        await this.deleteBrand(item)
        this.$hideConfirmationDialog()
      },
      Code: 'C4038'
    })
  }
}
