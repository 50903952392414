
import { ContactRequestStatus, MessageStatus } from '@/__generated__/globalTypes'
import DebounceMixin, { debounceDelay } from '@/mixins/debounceMixin'
import QUERIES from '@/queries/queries'
import { RouteNames } from '@/router/routeNames'
import { toLocaleDateString } from '@/utils/dateUtils'
import { buildRequestVariables, defaultFooterProps, TableFilter } from '@/utils/tableUtils'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataOptions, DataTableHeader } from 'vuetify'

import {
  GetContactRequestsProjectsAndRealEstatesDocument,
  GetContactRequestsProjectsAndRealEstatesQuery
} from './gql/__generated__/getProjectsAndRealEstates.query'
import {
  ContactRequestDetailFragment,
  GetContactRequestsQuery,
  GetContactRequestsQueryVariables,
  GetContactRequestsDocument
} from './gql/__generated__/getContactRequests.query'
import { ContactRequestItem } from './types'

@Component({
  mixins: [DebounceMixin]
})
export default class ContactRequestsListView extends Vue {
  contactRequests: ContactRequestDetailFragment[] = []
  projectsAndRealEstates: GetContactRequestsProjectsAndRealEstatesQuery | null = null
  totalCount = 0
  tableOptions: DataOptions = {} as DataOptions
  initialPageLoaded = false
  defaultFooterProps = defaultFooterProps
  RouteNames = RouteNames
  selectedProject = ''
  selectedRealEstate = ''
  projectsLoading = false

  search = ''

  searchFilters: TableFilter[] = [
    {
      keyPath: 'realEstate.contact.email.address',
      value: '',
      filterType: 'contains'
    }
  ]

  statusFilters: TableFilter[] = [
    {
      keyPath: 'currentStatus',
      value: ''
    }
  ]

  closedStatusFilters: TableFilter[] = [
    {
      keyPath: 'currentStatus',
      value: MessageStatus.Closed,
      filterType: 'neq'
    }
  ]

  hideClosedRequests = true

  changeSearch: ((value: string) => void) | null = null

  @Watch('selectedProject')
  onSelectedProjectChanged() {
    this.selectedRealEstate = ''
  }

  created() {
    this.tableOptions.sortBy = [
      'requested',
      'requestStatus.updateDate',
      'requestStatus.status',
      'realEstate.contact.email.address'
    ]
    this.tableOptions.sortDesc = [true]

    this.$apollo.addSmartQuery<GetContactRequestsQuery, GetContactRequestsQueryVariables>(
      QUERIES.GetContactRequestsQuery,
      {
        query: GetContactRequestsDocument,
        variables: () => ({
          ...this.requestForContactRequestVariables
        }),
        update: (data) => data,
        result: (result) => {
          if (result.data) {
            this.contactRequests = result.data.contactRequestsAsync?.items ?? []
            this.totalCount = result.data?.contactRequestsAsync?.totalCount ?? 0
            this.initialPageLoaded = true
          }
        },
        error: (error) => {
          this.$store.dispatch('showErrorDialog', {
            Code: 'E4205',
            Message: error.message
          })
        }
      }
    )

    this.$apollo.addSmartQuery<GetContactRequestsProjectsAndRealEstatesQuery>(
      'GetContactRequestsProjectsAndRealEstatesQuery',
      {
        query: GetContactRequestsProjectsAndRealEstatesDocument,
        fetchPolicy: 'network-only',
        update: (data) => data,
        result: (result) => {
          this.projectsAndRealEstates = result.data ?? null
        },
        watchLoading: (isLoading: boolean) => (this.projectsLoading = isLoading)
      }
    )
  }

  mounted() {
    this.tableOptions.page = 1
    this.changeSearch = this.debounce(() => {
      // Set the search string in all the filters
      Object.values(this.searchFilters).forEach((filter) => {
        filter.value = this.search
      })
    }, debounceDelay)
  }

  onDefaultFilterUpdate() {
    this.tableOptions.page = 1
  }

  get requestForContactRequestVariables() {
    const filterList = [this.searchFilters, this.statusFilters, [this.realEstateFilter]]
    if (this.hideClosedRequests) filterList.push(this.closedStatusFilters)
    return buildRequestVariables(this.tableOptions, filterList)
  }

  get language() {
    return this.$i18n.locale
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('contactRequests.list.headers.requester').toString(),
        value: 'realEstate.contact.email.address',
        width: '12%',
        sortable: true
      },
      {
        text: this.$t('contactRequests.list.headers.project').toString(),
        value: 'project',
        width: '10%',
        sortable: false
      },
      {
        text: this.$t('contactRequests.list.headers.buildNr').toString(),
        value: 'buildNumber',
        width: '7%',
        sortable: false
      },
      {
        text: this.$t('contactRequests.list.headers.status').toString(),
        value: 'requestStatus.status',
        width: '12%',
        sortable: true
      },

      {
        text: this.$t('contactRequests.details.contactPreference').toString(),
        value: 'contactPreference',
        width: '12%',
        sortable: false
      },
      {
        text: this.$t('contactRequests.list.headers.requestDate').toString(),
        value: 'requested',
        width: '8%',
        sortable: true
      },
      {
        text: this.$t('contactRequests.list.headers.modifiedDate').toString(),
        value: 'requestStatus.updateDate',
        width: '10%',
        sortable: true
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'end',
        width: '0%'
      }
    ]
  }

  get contactRequestItems() {
    return this.contactRequests.flatMap((contactRequest) => {
      return {
        contactPreference: contactRequest.contactType,
        contactRequestId: contactRequest.id,
        sender: contactRequest?.messages?.[0]?.sender ?? '',
        project: contactRequest?.realEstate?.project?.name ?? '',
        buildNumber: contactRequest?.realEstate?.buildNumber ?? '',
        requestStatus: contactRequest?.requestStatus?.status ?? ContactRequestStatus.New,
        requested: toLocaleDateString(contactRequest?.requested, this.language) ?? '',
        dateModified: toLocaleDateString(contactRequest?.requestStatus?.updateDate, this.language) ?? ''
      }
    }) as ContactRequestItem[]
  }

  get availableProjects() {
    return this.projectsAndRealEstates?.projects?.items
  }

  get availableRealEstates() {
    if (!this.selectedProject) return []

    return (
      this.availableProjects
        ?.find((p) => p.id === this.selectedProject)
        ?.realEstates.sort((a, b) => {
          const aValue = parseInt(a.buildNumber || '') || a.buildNumber || 0
          const bValue = parseInt(b.buildNumber || '') || b.buildNumber || 0
          if (aValue == bValue) return 0
          else if (aValue > bValue) return 1
          return -1
        }) ?? []
    )
  }

  get realEstateFilter(): TableFilter {
    let value: string[] | null = null
    if (this.selectedRealEstate) {
      value = [this.selectedRealEstate]
    } else if (this.selectedProject) {
      value = this.availableRealEstates.map((re) => re.id)
    }

    return {
      keyPath: 'realEstateId',
      value,
      filterType: 'in'
    }
  }

  get contactRequestStatusListTranslated() {
    return Object.values(MessageStatus).map((status) => ({
      text: this.$t(`contactRequests.status.${status.toLocaleLowerCase()}`).toString(),
      value: status,
      disabled: this.hideClosedRequests && status === MessageStatus.Closed
    }))
  }

  clearStatusFilter() {
    if (this.hideClosedRequests && this.statusFilters[0]?.value === MessageStatus.Closed) {
      this.statusFilters[0].value = ''
    }
  }

  getStatusColor(status: MessageStatus) {
    switch (status) {
      case MessageStatus.New:
        return 'green'
      case MessageStatus.Pending:
        return 'yellow'
      case MessageStatus.Closed:
      default:
        return 'grey'
    }
  }
}
